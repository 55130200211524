import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Grid, Row } from "@frontend/common/src/elements/Grid/Grid";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { useTranslation } from "react-i18next";
import { LinkButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { Select } from "../../../../../components/Select/Select";
import { Checkbox } from "../../../../../components/Checkbox/Checkbox";
import { Order } from "../../../../../types/Order";
import { useActions, useAppState } from "../../../../../overmind";

type Props = {
  orders: Order[];
};

export const OrderFilter: FunctionComponent<Props> = ({ orders }) => {
  const { t } = useTranslation();
  const [projectOptions, setProjectOptions] = useState([]);
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const actions = useActions();

  const state = useAppState();

  useEffect(() => {
    const projects: {
      [index: string]: { label: string; value: string };
    } = {};
    const people: { [index: string]: { label: string | number; value: any } } =
      {};
    const years: { [index: number]: { label: string | number; value: any } } =
      {};
    const statuses: {
      [index: number]: { label: string | number; value: any };
    } = {};
    const products: {
      [index: string]: { label: string; value: any };
    } = {};
    orders.forEach((o) => {
      if (o.isProjectMaster) {
        projects[o.id] = {
          label: o.id,
          value: o.id,
        };
      }

      const employee = state.customerEmployees.find(
        (e) => e.id === o.customerEmployeeId
      );

      if (employee) {
        people[employee.id as any] = {
          label: `${employee.name} `,
          value: employee.id,
        };
      }

      if (o.delivery) {
        const date = o.delivery;
        years[date.getFullYear()] = {
          label:
            date.getFullYear() === 1900
              ? "Ukjent leveringsdato"
              : date.getFullYear(),
          value: date.getFullYear(),
        };
      }

      if (!statuses[o.status]) {
        statuses[o.status] = {
          label: o.status,
          value: o.valueChainStatus,
        };
      }
      o.subProjects.forEach((x) => {
        if (!products[x.product] && x.product !== "") {
          products[x.product] = {
            label: x.product,
            value: x.product,
          };
        }
      });
    });

    const thisYear = new Date().getFullYear();
    years[thisYear] = {
      label: thisYear,
      value: thisYear,
    };

    setProjectOptions(Object.values(projects).reverse());
    setPeopleOptions(
      Object.values(people).sort((a, b) =>
        `${a.label}`.localeCompare(`${b.label}`)
      )
    );
    setYearOptions(Object.values(years).reverse());
    setStatusOptions(Object.values(statuses).sort((a, b) => a.value - b.value));
    setProductOptions(Object.values(products));
  }, [orders, state.customerEmployees, state.pretreEmplyees]);

  return (
    <Grid>
      <Row>
        <Col size={1}>
          <Select
            label={t("Project no.")}
            options={projectOptions}
            selectedValue={state.orders.orderFilter.projectId || ""}
            onSelectionChanged={(value) =>
              actions.orders.changeOrderFilter({
                projectId: value || null,
              })
            }
          />
          <FormInput
            label={t("FreeText")}
            type="text"
            name="freeText"
            defaultValue={`${state.orders.orderFilter.freeText || ""}`}
            onChange={(value) =>
              actions.orders.changeOrderFilter({
                freeText: value || null,
              })
            }
          />
          <FormInput
            label={t("Customer Order no.")}
            type="text"
            name="customerOrderNumber"
            defaultValue={`${
              state.orders.orderFilter.customerOrderNumber || ""
            }`}
            onChange={(value) =>
              actions.orders.changeOrderFilter({
                customerOrderNumber: value || null,
              })
            }
          />
          <Select
            label={t("Status")}
            options={statusOptions}
            selectedValue={state.orders.orderFilter.status || ""}
            onSelectionChanged={(value) =>
              actions.orders.changeOrderFilter({
                status: parseInt(value, 10) || null,
              })
            }
          />
        </Col>
        <Col size={1}>
          <Select
            label={t("Contact")}
            options={peopleOptions}
            selectedValue={state.orders.orderFilter.contactId || ""}
            onSelectionChanged={(value) =>
              actions.orders.changeOrderFilter({
                contactId: value || null,
              })
            }
          />
          <Select
            label={t("Product Group")}
            options={productOptions}
            selectedValue={state.orders.orderFilter.product || ""}
            onSelectionChanged={(value) =>
              actions.orders.changeOrderFilter({
                product: value,
              })
            }
          />
          <Select
            label={t("Delivery year")}
            options={yearOptions}
            selectedValue={state.orders.orderFilter.deliveryYear || ""}
            onSelectionChanged={(value) =>
              actions.orders.changeOrderFilter({
                deliveryYear: parseInt(value, 10) || null,
              })
            }
          />
          <FormInput
            label="Prosjektnavn"
            type="text"
            name="marked"
            defaultValue={`${state.orders.orderFilter.projectName || ""}`}
            onChange={(value) =>
              actions.orders.changeOrderFilter({ projectName: value })
            }
          />
        </Col>
        <Col
          size={1}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "3.1rem",
          }}
        >
          <Checkbox
            checked={state.orders.orderFilter.hideDeliveredOrders || false}
            label={t("Hide delivered orders")}
            onChange={(e) =>
              actions.orders.changeOrderFilter({
                hideDeliveredOrders: e.target.checked,
              })
            }
          />
          <LinkButton
            style={{ textAlign: "left" }}
            onClick={() => actions.orders.clearOrderFilter()}
          >
            Tøm filter
          </LinkButton>
        </Col>
      </Row>
    </Grid>
  );
};
