import React from "react";
import { EmployeeMessageReferenceType } from "@frontend/common/src/types/EmployeeMessage";
import { Callout } from "@frontend/common/src/elements/Callout/Callout";
import { useAppState } from "../../overmind";
import { EmployeeLink } from "../EmployeeLink/EmployeeLink";

type Props = {
  employeeId?: string;
};
export const SalesPersonBox = ({ employeeId }: Props) => {
  const state = useAppState();
  const employee = state.pretreEmplyees.find((e) => e.id === employeeId);

  return (
    <Callout>
      <span>Ansvarlig uteselger</span>

      {employee ? (
        <EmployeeLink
          employee={employee}
          referenceId="0"
          referenceType={EmployeeMessageReferenceType.CUSTOMER}
        />
      ) : (
        <div>Ingen ansvarlig uteselger</div>
      )}
    </Callout>
  );
};
