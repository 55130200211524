import React, { FunctionComponent } from "react";
import { Wrapper } from "../../components/Table/elements/SortIcon/SortIcon";

const plus = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#CA0000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
  </svg>
);
const minus = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#CA0000" d="M19,13H5V11H19V13Z" />
  </svg>
);

type Props = {
  expanded: boolean;
};
export const ExpandIcon: FunctionComponent<Props> = ({ expanded }) => {
  return (
    <Wrapper
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {expanded ? minus : plus}
    </Wrapper>
  );
};
