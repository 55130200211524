/* eslint-disable no-param-reassign */
import { Page } from "@frontend/common/src/types/Page";
import { Context } from "..";
import { CreateOrderSteps } from "../../types/CreateOrderSteps";
import { createUrl } from "../../app/pages";

export const openLogin = async ({ state }: Context) => {
  state.navigation.currentPage = Page.LOGIN;
};

export const openForgotPassword = async ({ state }: Context) => {
  state.navigation.currentPage = Page.RESET_PASSWORD;
};

export const openHome = async ({ state }: Context) => {
  state.navigation.currentPage = Page.HOME;
};

export const openTechnicalServices = async ({ state }: Context) => {
  state.navigation.currentPage = Page.TECHNICAL_SERVICES;
};

export const openOrderList = async (
  { state, effects }: Context,
  routeArgs: any
) => {
  const url = createUrl(Page.ORDER_LIST);
  effects.persistentCache.set("returnUrl", url);
  if (state.mesterIdLogin && state.customer == null) {
    effects.router.navigateTo(createUrl(Page.CUSTOMER_SELECTOR));
  } else {
    state.navigation.currentPage = Page.ORDER_LIST;

    if (routeArgs.params && routeArgs.params.side) {
      state.orders.currentPage = parseInt(routeArgs.params.side, 10);
    } else {
      state.orders.currentPage = 1;
    }

    try {
      state.orders.isFetching = true;
      state.orders.orders = await effects.api.order.getAllForCustomerId(
        state.customer.id
      );
      state.orders.isFetching = false;
    } catch (error) {
      console.log(error);
    }
  }
};

export const openViewOrder = async (
  { state, effects }: Context,
  routeArgs: any
) => {
  state.navigation.currentPage = Page.VIEW_ORDER;
  state.orders.currentOrderId = routeArgs.params.id;

  try {
    let projectId = routeArgs.params.id;
    if (projectId.includes("_")) {
      // eslint-disable-next-line prefer-destructuring
      projectId = projectId.split("_")[0];
    }
    let customerId = state.customer?.id;
    if (customerId === undefined) {
      const proj = await effects.api.order.getByIdWithoutCustomer(projectId);
      customerId = proj.customerNumber;

      const customer = state.mesterIdCustomers.find((x) => x.id === customerId);
      state.customer = { ...customer };
      state.customerEmployees =
        await effects.api.customerEmployee.getAllForCustomerId(customer.id);
    }
    state.orders.orders = await effects.api.order.getAllForCustomerId(
      customerId
    );

    if (!state.orders.orders.find((order) => order.id === projectId)) {
      state.error = { message: "Fant ikke ordre" };
      state.navigation.currentPage = Page.ERROR;
    } else {
      state.orders.currentOrder.documents =
        await effects.api.order.getDocumentsForOrder(customerId, projectId);
    }
  } catch (error) {
    const cast = error as string;
    if (cast.includes("401")) {
      const url = createUrl(Page.VIEW_ORDER, {
        id: routeArgs.params.id,
      });
      effects.persistentCache.set("returnUrl", url);
    }
    console.log(error);
  }
};

export const openViewOrderProject = async (
  { state, effects }: Context,
  routeArgs: any
) => {
  if (state.auth.user === null) {
    effects.router.navigateTo(createUrl(Page.HOME));
    return;
  }

  state.navigation.currentPage = Page.VIEW_ORDER_PROJECT;
  state.orders.currentOrderProjectId = routeArgs.params.id;
  try {
    let projectId = routeArgs.params.id;
    if (projectId.includes("_")) {
      // eslint-disable-next-line prefer-destructuring
      projectId = projectId.split("_")[0];
    }
    let customerId = state.customer?.id;
    if (customerId === undefined) {
      const proj = await effects.api.order.getByIdWithoutCustomer(projectId);
      customerId = proj.customerNumber;

      const customer = state.mesterIdCustomers.find((x) => x.id === customerId);
      state.customer = { ...customer };
      state.customerEmployees =
        await effects.api.customerEmployee.getAllForCustomerId(customer.id);
    }
    state.orders.orders = await effects.api.order.getAllForCustomerId(
      customerId
    );
    if (
      !state.orders.orders.find(
        (order) => order.id === state.orders.currentOrderProjectId
      )
    ) {
      state.error = { message: "Fant ikke ordre" };
      state.navigation.currentPage = Page.ERROR;
    } else {
      const documents = await effects.api.order.getDocumentsForOrder(
        customerId,
        state.orders.currentOrderProjectId
      );
      state.orders.currentOrderProject.documents = documents;
    }
  } catch (error) {
    const cast = error as string;
    if (cast.includes("401")) {
      const url = createUrl(Page.VIEW_ORDER_PROJECT, {
        id: routeArgs.params.id,
      });
      effects.persistentCache.set("returnUrl", url);
    }
    console.log(error);
  }
};

export const openCreateOrderStep = async (
  { state, effects, actions }: Context,
  routeArgs: any
) => {
  if (state.mesterIdLogin && state.customer == null) {
    effects.router.navigateTo(createUrl(Page.CUSTOMER_SELECTOR));
  } else {
    // eslint-disable-next-line no-shadow
    const steps: { [index: string]: any } = {
      velg: CreateOrderSteps.PROJECT_TYPE,
      velgny: CreateOrderSteps.PROJECT_TYPE,
      kundeinfo: CreateOrderSteps.CUSTOMER_INFO,
      produktvalg: CreateOrderSteps.CHOOSE_PRODUCT,
      vedlegg: CreateOrderSteps.ATTACHMENTS,
      oppsummering: CreateOrderSteps.SUMMARY,
      kvittering: CreateOrderSteps.RECEIPT,
    };
    state.navigation.currentPage = Page.CREATE_ORDER;
    // eslint-disable-next-line no-prototype-builtins
    if (routeArgs.params.slug && steps.hasOwnProperty(routeArgs.params.slug)) {
      if (routeArgs.params.slug === "velgny") {
        actions.orders.clearAutofillAndDraftOrder();
      }
      state.activeCreateOrderStep = steps[routeArgs.params.slug];
      if (state.activeCreateOrderStep > state.orders.lastCompletedOrderStep) {
        state.orders.lastCompletedOrderStep = state.activeCreateOrderStep;
      }
    } else {
      // eslint-disable-next-line no-multi-assign
      state.activeCreateOrderStep = state.orders.lastCompletedOrderStep = 0;
    }
  }
};

export const openUser = async ({ state }: Context) => {
  state.navigation.currentPage = Page.USER;
};

export const openCompanyPage = async ({ state }: Context) => {
  state.navigation.currentPage = Page.COMPANY;
};

export const openDocumentationPage = async ({ state }: Context) => {
  state.navigation.currentPage = Page.DOCUMENTATION;
};

export const emulate = async ({ effects, state }: Context, routeArgs: any) => {
  const { customerId } = routeArgs.params;
  effects.tokenService.clear();
  state.customer = null;
  state.currentEmployeeId = null;
  state.auth.user = null;
  effects.persistentCache.set("customerId", customerId);
  effects.router.navigateTo(createUrl(Page.LOGIN));
};

export const auth = async ({ state }: Context) => {
  state.navigation.currentPage = Page.AUTH;
};

export const openCompanySelector = async ({ state, effects }: Context) => {
  if (state.mesterIdLogin && state.mesterIdCustomers.length > 1) {
    state.navigation.currentPage = Page.CUSTOMER_SELECTOR;
  } else {
    effects.router.navigateTo(createUrl(Page.ORDER_LIST));
  }
};
