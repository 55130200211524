import React, { FunctionComponent } from "react";

import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextArea } from "@frontend/common/src/components/TextArea/TextArea";
import * as validators from "@frontend/common/src/validators";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "../../../components/Select/Select";

import { useAppState } from "../../../overmind";
import { OfferOptionType } from "../../../types/OfferOption";

import { yesNoOptions } from "../../../data/selectOptions";
import { BlockButton } from "../../../components/Buttons/Buttons.styles";
import { DraftOrderSubProject } from "../../../types/DraftOrder/DraftOrderSubProject";
import { makeId } from "../../../helpers/GenerateId";

export type FormProps = {
  subProjects: DraftOrderSubProject[];
  setSubProjects: (projects: DraftOrderSubProject[]) => void;
  setActiveForm: (form: string) => void;
  selectedSubProject: DraftOrderSubProject | undefined;
  setSelectedSubProject: (project: DraftOrderSubProject | undefined) => void;
  autoFill: boolean;
};

export const RoofForm: FunctionComponent<FormProps> = ({
  setSubProjects,
  setActiveForm,
  subProjects,
  selectedSubProject,
  setSelectedSubProject,
  autoFill,
}) => {
  const { t } = useTranslation();

  const { offerOptions, orders } = useAppState();

  const onSubmit = (data: DraftOrderSubProject) => {
    if (data.projectId === undefined || data.projectId.length < 1) {
      // eslint-disable-next-line no-param-reassign
      data.projectId = makeId(8);
    }
    if (selectedSubProject !== undefined) {
      const newProjs = subProjects.map((x) => {
        if (x.projectId === data.projectId) {
          return {
            ...x,
            comment: data.comment,
            rangeDistance:
              data.rangeDistance ?? selectedSubProject.rangeDistance,
            roofType: data.roofType ?? selectedSubProject.roofType,
            roofingType: data.roofingType ?? selectedSubProject.roofingType,
            angle: data.angle ?? selectedSubProject.angle,
            protrusion: data.protrusion ?? selectedSubProject.protrusion,
            workingWidth: data.workingWidth ?? selectedSubProject.workingWidth,
            additionalCrane:
              data.additionalCrane ?? selectedSubProject.additionalCrane,
          };
        }
        return x;
      });
      setSubProjects(newProjs);
    } else {
      setSubProjects([...subProjects, data]);
    }
    setActiveForm("");
    setSelectedSubProject(undefined);
  };
  const onCancel = () => {
    setActiveForm("");
    setSelectedSubProject(undefined);
  };

  const roof = yup.object().shape({
    roofType: validators.requiredField,
    roofingType: validators.requiredField,
    angle: validators.decimalField,
    rangeDistance: validators.positiveNumeric,
    protrusion: validators.positiveNumeric.min(0, "Må være over 0"),
    additionalCrane: !orders.currentNewOrder.quotation
      ? validators.requiredField
      : null,
    workingWidth: validators.positiveNumeric,
  });
  const { register, handleSubmit, errors } = useForm<DraftOrderSubProject>({
    // @ts-ignore
    resolver: yupResolver(roof),
    defaultValues: selectedSubProject,
  });
  const roofTypes = offerOptions
    .filter((o) => o.type === OfferOptionType.ROOF)
    .map((o) => ({ label: o.description, value: o.id }));

  const roofingTypes = offerOptions
    .filter((o) => o.type === OfferOptionType.ROOFING)
    .map((o) => ({ label: o.description, value: o.id }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <div>
          <input ref={register} type="hidden" value="roof" name="type" />
          <input ref={register} type="hidden" name="projectId" />

          <Select
            name="roofType"
            label={t("Pick roof type")}
            options={roofTypes}
            required
            inputRef={register}
            error={errors.roofType?.message}
            disabled={
              autoFill &&
              selectedSubProject.roofType !== null &&
              selectedSubProject.roofType.length > 0
            }
          />
          <Select
            name="roofingType"
            label={t("Roofing")}
            options={roofingTypes}
            inputRef={register}
            error={errors.roofingType?.message}
            disabled={
              autoFill &&
              selectedSubProject.roofingType !== null &&
              selectedSubProject.roofType.length > 0
            }
            required
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <FormInput
              label={t("Roof angle")}
              type="number"
              name="angle"
              suffix="grader"
              inputRef={register}
              disabled={autoFill}
              error={errors.angle?.message}
            />
            <FormInput
              label={t("Range distance")}
              type="number"
              suffix="mm"
              name="rangeDistance"
              inputRef={register}
              disabled={autoFill}
              error={errors.rangeDistance?.message}
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <FormInput
              label={t("Protrusion")}
              type="number"
              name="protrusion"
              suffix="mm"
              inputRef={register}
              disabled={autoFill}
              error={errors.protrusion?.message}
            />

            <FormInput
              label={t("Virkesbredde")}
              type="number"
              name="workingWidth"
              suffix="mm"
              inputRef={register}
              disabled={autoFill}
              error={errors.workingWidth?.message}
            />
          </div>

          <Select
            name="additionalCrane"
            label={t("Ekstra kraning ")}
            options={yesNoOptions}
            inputRef={register}
            error={errors.additionalCrane?.message}
            disabled={
              autoFill &&
              selectedSubProject.additionalCrane !== null &&
              selectedSubProject.additionalCrane.length > 0
            }
            required={!orders.currentNewOrder.quotation}
          />

          <TextArea
            label={t("Kommentar")}
            name="comment"
            rows={3}
            inputRef={register}
            placeholder={t("Write other required info here")}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "30%",
          }}
        >
          <BlockButton style={{ marginBottom: "2em" }} type="submit">
            {selectedSubProject !== undefined ? "Oppdater" : "Legg til"}
          </BlockButton>
          <BlockButton onClick={() => onCancel()} type="button">
            Avbryt
          </BlockButton>
        </div>
      </div>
    </form>
  );
};
