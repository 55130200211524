import { User } from "@frontend/common/src/types/User";

type State = {
  token: string;
  user: User;
};

export const state: State = {
  token: null,
  user: null,
};
