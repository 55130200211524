import {
  CustomerEmployee,
  UpdateCustomerEmployee,
} from "@frontend/common/src/types/CustomerEmployee";
import { BaseService } from "../BaseService";
import { endpoints } from "../endpoints";
import { ICustomerEmployeeService } from "./ICustomerEmployeeService";

export class CustomerEmployeeService
  extends BaseService
  implements ICustomerEmployeeService
{
  async getAllForCustomerId(customerId: string): Promise<CustomerEmployee[]> {
    const results: any[] = await this.makeRequest(
      endpoints.customerEmployee.getAll,
      { customerId }
    ).then((r) => r.data);
    return results.map(CustomerEmployeeService.convert);
  }

  async getByUserName(username: string): Promise<CustomerEmployee> {
    const results: any = await this.makeRequest(endpoints.auth.getByUsername, {
      username,
    }).then((r) => r.data);
    return CustomerEmployeeService.convert(results);
  }

  async update(
    customerId: string,
    customerEmployee: UpdateCustomerEmployee,
    loggedInEmail: string
  ): Promise<boolean> {
    return await this.makeRequest(endpoints.customerEmployee.update, {
      customerId,
      employeeId: customerEmployee.id,
      kundenr: customerId,
      kontakt_Navn: customerEmployee.name,
      kontakt_Epost: customerEmployee.email,
      kontakt_Telefon: customerEmployee.phone,
      kontakt_Mobiltlf: customerEmployee.mobile,
      kontakt_Stilling: customerEmployee.position,
      kontakt_BlokkerPortaltilgang: !customerEmployee.active,
      kontakt_Slutta: customerEmployee.hasQuit,
      passord: customerEmployee.password,
      nyttPassord: customerEmployee.newPassword,
      loggedInEmail,
    }).then((r) => r.data);
  }

  async create(
    customerId: string,
    customerEmployee: UpdateCustomerEmployee
  ): Promise<CustomerEmployee> {
    return await this.makeRequest(endpoints.customerEmployee.create, {
      customerId,
      kontakt_Navn: customerEmployee.name,
      kontakt_Epost: customerEmployee.email,
      kontakt_Telefon: customerEmployee.phone,
      kontakt_Mobiltlf: customerEmployee.mobile,
      kontakt_Stilling: customerEmployee.position,
      kontakt_Passord_Portal: customerEmployee.newPassword,
    }).then((r) => r.data);
  }

  async resetPassword(
    customerId: string,
    customerEmployeeId: number
  ): Promise<void> {
    return await this.makeRequest(endpoints.customerEmployee.resetPassword, {
      customerId,
      customerEmployeeId,
    }).then((r) => r.data);
  }

  static convert(employee: any): CustomerEmployee {
    return {
      id: employee.kontaktID,
      name: employee.kontakt_Navn,
      email: employee.kontakt_Epost,
      phone: employee.kontakt_Telefon,
      mobile: employee.kontakt_Mobiltlf,
      position: employee.kontakt_Stilling,
      active: !employee.kontakt_BlokkerPortaltilgang,
      hasQuit: employee.kontakt_Slutta,
      customerId: employee.kundenr,
      hasPassword: employee.hasPassword,
    };
  }
}
