import styled from "styled-components";
import { PreTreColors } from "../../styles/constants";

export const Card = styled("div")`
  max-width: 100%;
  height: auto;
  max-height: 100%;
  padding: 2.375rem 2.875rem;
  background-color: ${PreTreColors.weakBrite};
  border-radius: 10px;
`;
export const CardHeader = styled("h1")`
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 2.625rem;
  font-weight: 700;
  color: ${PreTreColors.beis};
`;
export const ShortcutLink = styled("a")`
  margin-top: 15px;
  color: ${PreTreColors.beis};
  font-size: 1.125rem;
  font-weight: 700;
  padding-bottom: 2px;
  text-decoration: underline;
  cursor: pointer;
`;
export const ArrowIcon = styled.svg`
  width: 27px;
  height: 20px;
  margin-right: 0.6875rem;
  margin-top: 4px;
  float: left;
`;
