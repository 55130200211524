import React, { FunctionComponent } from "react";
import { PrecutOpening } from "../../../types/DraftOrder/DraftOrderSubProject";
import { Select } from "../../../components/Select/Select";
import { Checkbox } from "../../../components/Checkbox/Checkbox";

interface Props {
  opening: PrecutOpening;
  changeOpening: (id: string, field: string, value: any) => void;
  deleteOpening: (id: string) => void;
  idx: number;
}
const numOfOpenings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const height = [
  400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700,
  1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000,
  3100, 3200, 3300, 3400, 3500, 3600, 3700, 3800, 3900, 4000, 4100, 4200, 4300,
  4400, 4500, 4600, 4700, 4800, 4900, 5000, 5100, 5200,
];
const width = [
  400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700,
  1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000,
  3100, 3200, 3300, 3400, 3500, 4000, 4500, 5000, 5500,
];
const PrecutOpeningRow: FunctionComponent<Props> = ({
  opening,
  deleteOpening,
  changeOpening,
  idx,
}) => {
  const openings = numOfOpenings.map((o) => ({
    label: o.toString(),
    value: o,
  }));
  const h = height.map((x) => ({ label: x.toString(), value: x }));
  const w = width.map((x) => ({ label: x.toString(), value: x }));
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "2rem",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <span style={{ fontWeight: "bold", width: "5rem" }}>
        Åpning #{idx + 1}{" "}
      </span>
      <Select
        selectedValue={opening.quantity}
        selectWidth="8rem"
        options={openings}
        onSelectionChanged={(x) => changeOpening(opening.id, "quantity", x)}
      />
      <Select
        selectedValue={opening.width}
        selectWidth="8rem"
        options={w}
        onSelectionChanged={(x) => changeOpening(opening.id, "width", x)}
      />
      <Select
        selectedValue={opening.height}
        selectWidth="8rem"
        options={h}
        onSelectionChanged={(x) => changeOpening(opening.id, "height", x)}
      />
      <span style={{ alignSelf: "end" }}>
        <Checkbox
          checked={opening.bearing}
          onChange={(x) =>
            changeOpening(opening.id, "bearing", x.target.checked)
          }
        />
      </span>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <span
        style={{ cursor: "pointer" }}
        onClick={() => deleteOpening(opening.id)}
      >
        {" "}
        Fjern{" "}
      </span>
    </div>
  );
};

export default PrecutOpeningRow;
