import React, { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";

const Menu = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
`;

const MenuItem = styled.section`
  text-align: center;
  padding: 0.25rem;
`;

const MenuItemHeader = styled.h4``;

const MenuItemImg = styled.img<{ isSelected?: boolean }>`
  max-width: 200px;
  border-radius: 0.25rem;
  border: 0.125rem solid
    ${(props) => (props.isSelected ? "red" : "transparent")};
`;

interface Props {
  key: number;
  /** Header text */
  text: string;
  /** The URL for the image */
  src: string;
}

export const SelectionGrid: FunctionComponent<{
  items: Props[];
  // eslint-disable-next-line no-unused-vars
  onItemClicked: (i: Props, e: any) => void;
}> = ({ items, onItemClicked }) => {
  const [elements, setElements]: any[] = useState<Element[]>();
  const [selected, setSelected] = useState<Props | undefined>();

  const renderer = (i: Props) => {
    return (
      <MenuItem
        onClick={(e) => {
          setSelected(i);
          onItemClicked(i, e);
        }}
      >
        <MenuItemHeader>{i.text}</MenuItemHeader>
        <MenuItemImg
          isSelected={selected && selected.key === i.key}
          src={i.src}
          alt={i.text}
        />
      </MenuItem>
    );
  };

  useEffect(() => setElements(items.map(renderer)), [items, selected]);

  return <Menu>{elements}</Menu>;
};
