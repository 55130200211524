import { css } from "styled-components";
import { PreTreColors } from "./constants";

type Props = {
  error?: boolean;
};
export const baseInputStyles = css<Props>`
  border: 1px solid
    ${({ error }) => (error ? `red` : PreTreColors.borderlineGray)};
  background: #f4f4f4;

  box-sizing: border-box;
  border-radius: 5px;
  color: ${PreTreColors.beis};

  font-size: 1.125rem;
  font-weight: 700;
  padding: 0.7rem;
`;
