import React, { ChangeEvent, FunctionComponent } from "react";
import { Input } from "./FileInput.styles";
import { Label } from "../Label/Label";
import { FormControl, Error } from "../FormControl/FormControl";
import { RequiredFieldIndicator } from "../RequiredFieldIndicator/RequiredFieldIndicator";

type Props = {
  htmlFor?: string;
  label?: string;
  name: string;
  inputRef?: any;
  error?: any;
  required?: boolean;
  onChange?: (value: FileList) => void;
  multiple?: any;
};

export const FileInput: FunctionComponent<Props> = ({
  htmlFor,
  label,
  name,
  inputRef,
  error,
  required,
  onChange,
  multiple,
}) => {
  return (
    <FormControl>
      <Label htmlFor={htmlFor}>
        {label || null}
        {required ? <RequiredFieldIndicator /> : ""}
      </Label>
      <Input
        name={name}
        type="file"
        ref={inputRef}
        error={!!error}
        multiple={multiple}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange ? onChange(e.target.files) : () => {}
        }
      />
      {error ? <Error>{error}</Error> : null}
    </FormControl>
  );
};
