import React, { FunctionComponent } from "react";

import { Grid, Row, Col } from "@frontend/common/src/elements/Grid/Grid";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { formatDate } from "@frontend/common/src/helpers/date";
import { PhoneLink } from "@frontend/common/src/components/PhoneLink/PhoneLink";
import { Page as PageConstants } from "@frontend/common/src/types/Page";
import { Link } from "../../../elements/Link/Link";

import { DocumentList } from "../../../components/DocumentList/DocumentList";
import { OrderList } from "../../../components/OrderList/OrderList";

import {
  DetailsBox,
  Legend,
  Text,
  Group,
} from "../../../components/DetailsBox/DetailsBox";
import { SketchFabEmbed } from "../../../components/SketchFabEmbed/SketchFabEmbed";
import { useActions, useAppState } from "../../../overmind";
import { Header } from "../../../elements/Header/Header";
import { formatMoney } from "../../../helpers/money";
import {
  BlockButton,
  LinkButton,
} from "../../../components/Buttons/Buttons.styles";
import { router } from "../../../overmind/effects";
import { createUrl } from "../../../app/pages";
import { FileUploadModal } from "../../../modals/FileUploadModal/FileUploadModal";
import { getDeliveryDateText } from "../../../helpers/order";

export const ViewOrderProjectPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const actions = useActions();
  const {
    customerEmployees,
    orders,
    customer,
    modals: { fileUploadModal },
  } = useAppState();

  const { currentOrderProject } = orders;
  if (!currentOrderProject) {
    return <div>{t("Loading")}</div>;
  }

  const customerContactPerson = customerEmployees.find(
    (e) => e.id === currentOrderProject.customerEmployeeId
  );

  const projectSum =
    currentOrderProject.subProjects.length > 0
      ? currentOrderProject.subProjects
          .map((o) => o.sum)
          .reduce((sum, val) => sum + val)
      : 0;
  const onNewOrderClicked = async () => {
    await actions.orders.getOrderAutofill({
      customerId: customer.id,
      offerId: currentOrderProject.id,
    });
    actions.orders.setOrderConverted(true);
    router.navigateTo(
      createUrl(PageConstants.CREATE_ORDER, { slug: "kundeinfo" })
    );
  };
  return (
    <Page title={`Prosjekt #${currentOrderProject.id}`}>
      <Grid>
        <Row>
          <Col size={1}>
            <DetailsBox>
              <tbody>
                <Group>
                  <Legend>{t("Created")}</Legend>
                  <Text>{formatDate(currentOrderProject.orderDate)}</Text>
                </Group>

                <Group>
                  <Legend>Prosjektnavn</Legend>
                  <Text>{currentOrderProject.projectName}</Text>
                </Group>
                <Group>
                  <Legend>{t("Status")}</Legend>
                  <Text>{currentOrderProject.status}</Text>
                </Group>
                <Group>
                  <Legend>{t("Customer order no")}</Legend>
                  <Text>
                    {currentOrderProject.customerOrderNumber || "Ikke satt"}
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Customer ref.")}</Legend>
                  <Text>
                    {customerContactPerson
                      ? customerContactPerson.name
                      : "Ikke satt"}
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Delivery terms")}</Legend>
                  <Text>{currentOrderProject.deliveryTerms}</Text>
                </Group>
                <Group>
                  <Legend>{t("Delivery addr.")}</Legend>
                  {currentOrderProject.deliveryAddress.toLowerCase() ===
                  "avhentet" ? (
                    <Text>Avhentet</Text>
                  ) : (
                    <Text>
                      <Link
                        target="_blank"
                        href={`https://www.google.no/maps/search/${encodeURI(
                          currentOrderProject.deliveryAddress
                        )}`}
                      >
                        {currentOrderProject.deliveryAddress}
                      </Link>
                    </Text>
                  )}
                </Group>
                <Group>
                  <Legend>{t("Project price")}</Legend>
                  <Text>{formatMoney(projectSum)}</Text>
                </Group>
              </tbody>
            </DetailsBox>
          </Col>
          <Col size={1}>
            <DetailsBox>
              <tbody>
                <Group>
                  <Legend>{t("Constructor")}</Legend>
                  <Text>{currentOrderProject.constructionName}</Text>
                </Group>

                <Group>
                  <Legend>{t("Site phone")}</Legend>
                  <Text>
                    <PhoneLink
                      number={currentOrderProject.constructionContactPhone}
                    />
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Site contact")}</Legend>
                  <Text>{currentOrderProject.constructionContactName}</Text>
                </Group>
                <Group>
                  <Legend>Ønsket leveringsdato</Legend>
                  <Text>{getDeliveryDateText(currentOrderProject)}</Text>
                </Group>
              </tbody>
            </DetailsBox>
            {currentOrderProject.valueChainStatus === 1 && (
              <BlockButton
                style={{
                  width: "33%",
                  height: "25%",
                  fontSize: "1rem",
                  padding: "10px 0",
                }}
                onClick={() => onNewOrderClicked()}
              >
                {" "}
                Sett tilbud til ordre{" "}
              </BlockButton>
            )}
            {currentOrderProject.valueChainStatus === 4 && (
              <BlockButton
                style={{
                  width: "33%",
                  height: "25%",
                  fontSize: "1rem",
                  padding: "10px 0",
                }}
              >
                {" "}
                Godkjenn ordre{" "}
              </BlockButton>
            )}
          </Col>

          <Col size={1}>
            {currentOrderProject.embedId ? (
              <SketchFabEmbed
                embedHash={currentOrderProject.embedHash}
                embedId={currentOrderProject.embedId}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col size={2}>
            <div style={{ marginBottom: 10 }}>
              <Header type="secondary">{t("Orders in project")}</Header>
            </div>
            <OrderList
              noResultsText="Ingen ordre i prosjektet"
              orders={currentOrderProject.subProjects}
              columns={[
                "id",
                "projectName",
                "status",
                "delivery",
                "constructionName",
              ]}
            />
          </Col>
          <Col size={1}>
            <div style={{ marginBottom: 10 }}>
              <Header type="secondary" style={{ display: "inline" }}>
                {t("Documents")}
              </Header>
              {currentOrderProject.valueChainStatus === 0 && (
                <LinkButton
                  as="a"
                  onClick={() =>
                    actions.promptFileUpload(currentOrderProject.id)
                  }
                >
                  Legg til
                </LinkButton>
              )}
            </div>
            <DocumentList documents={currentOrderProject.documents} />
          </Col>
        </Row>
      </Grid>
      {fileUploadModal.isCurrent ? <FileUploadModal /> : null}
    </Page>
  );
};
