import styled from "styled-components";

type Props = {
  direction?: "row" | "column";
};
export const ButtonGroup = styled("div")<Props>`
  display: flex;
  margin-top: 2rem;
  flex-direction: ${(props) => props.direction || "row"};
`;
