import React from "react";
import { Calculation } from "../../../types/DraftOrder/Calculation";
import { Header } from "../../../elements/Header/Header";

export const CalcConditions = ({ calc }: { calc: Calculation }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {calc !== undefined &&
      (calc.error === undefined || calc.error === null) ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2em",
          }}
        >
          <div>
            <Header type="secondary">Forutsetninger</Header>
            <ul>
              {calc.preconditions !== null
                ? calc.preconditions.map((x) => <li>{x}</li>)
                : null}
            </ul>
          </div>
          <Header type="secondary">Forbehold</Header>
          <ul>
            {calc.conditions !== null
              ? calc.conditions.map((x) => <li>{x}</li>)
              : null}
          </ul>
        </div>
      ) : null}
    </>
  );
};
