import React, { FunctionComponent } from "react";
import { UpdateCustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { UserForm } from "../../components/forms/UserForm/UserForm";
import { useActions, useAppState } from "../../overmind";

export const UserPage: FunctionComponent = () => {
  const state = useAppState();
  const actions = useActions();

  if (!state.currentEmployee) {
    return <Page title="">Fant ikke bruker...</Page>;
  }

  const onSubmitData = (data: UpdateCustomerEmployee) => {
    // eslint-disable-next-line no-param-reassign
    data.id = state.currentEmployee.id;
    actions.changeCustomerEmployee(data);
  };

  return (
    <Page
      title={`#${state.currentEmployee.id} - ${state.currentEmployee.name}`}
    >
      <UserForm
        customerEmployee={state.currentEmployee}
        onSubmit={onSubmitData}
      />
    </Page>
  );
};
