import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/types/Page";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { createUrl } from "../../../app/pages";
import { BlockButton } from "../../../components/Buttons/Buttons.styles";
import { Header } from "../../../elements/Header/Header";
import { useActions, useAppState } from "../../../overmind";
import {
  Text,
  BoldText,
  PageFlexConstraint,
  StyledSection,
} from "./CreateOrderPage.styles";

export const Receipt: FunctionComponent = () => {
  const { t } = useTranslation();
  const actions = useActions();

  const {
    orders: { currentNewOrder: order, orderConverted, isQuote, autofill },
    customer,
  } = useAppState();
  const [orderId, setOrderId] = useState<string>();

  useEffect(() => {
    if (order.orderId) {
      actions.orders.commitSubProjs({
        customerId: customer.id,
        order,
        projectId: order.orderId,
        offerId: autofill?.offerId,
      });
      setOrderId(order.orderId);
    }
    actions.orders.clearAutofillAndDraftOrder();
  }, []);
  return (
    <PageFlexConstraint>
      <StyledSection>
        <Header type="secondary">7. {t("Summary")}</Header>
        <article>
          <Text>
            <CMSContent
              contentId={
                orderConverted
                  ? 1457
                  : isQuote
                  ? CMSArticles.OFFER_SUMMARY
                  : CMSArticles.ORDER_SUMMARY
              }
            />
          </Text>
          <BoldText>Ditt referansenummer er #{orderId}</BoldText>
        </article>
        <article style={{ padding: "1rem 0" }}>
          <BlockButton
            as="a"
            href={createUrl(Page.ORDER_LIST)}
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
          >
            {t("Back to my orders")}
          </BlockButton>
        </article>
      </StyledSection>
    </PageFlexConstraint>
  );
};
