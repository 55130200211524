import React, { FunctionComponent } from "react";

interface props {
  show: boolean;
}
const Tooltip: FunctionComponent<props> = ({ children, show }) => {
  return (
    <div
      style={{
        display: show ? "" : "none",
        position: "absolute",
        background: "rgba(0, 0, 0, 0.7)",
        color: "white",
        padding: "5px",
        borderRadius: "5px",
        bottom: "250%",
        zIndex: "100",
      }}
    >
      {children}
      <span
        style={{
          position: "absolute",
          top: "100%",
          left: "50%",
          borderWidth: "5px",
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0.7) transparent transparent",
        }}
      />
    </div>
  );
};

export default Tooltip;
