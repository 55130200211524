import styled from "styled-components";

import { PreTreColors } from "../../styles/constants";

export const DetailsBox = styled("table")`
  border-collapse: collapse;
`;

export const Legend = styled("th")`
  color: ${PreTreColors.beis};
  text-align: left;
  vertical-align: top;
  padding-right: 2rem;
`;

export const Text = styled("td")``;

export const Group = styled("tr")``;
