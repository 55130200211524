/* eslint-disable no-shadow */
export enum UserRole {
  ADMIN = "ADMIN",
  USER = "USER",
}
export enum UserType {
  COMPANY = "COMPANY",
  EMPLOYEE = "EMPLOYEE",
}

export type User = {
  id: string;
  customerId: string;
  username: string;
  role: UserRole;
  type: UserType;
  emulatedType?: UserType;
  isAdmin: boolean;
};
