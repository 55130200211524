/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { User } from "@frontend/common/src/types/User";
import { Page } from "@frontend/common/src/types/Page";
import { useAuth0 } from "@auth0/auth0-react";
import Logo from "../../../assets/img/pretre_logo.png";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { useActions, useAppState, useEffects } from "../../../overmind";
import { LayoutConstants } from "../../../styles/constants";
import { createUrl } from "../../pages";
import {
  BurgerBar,
  BurgerButton,
  Container,
  DropdownItem,
  Item,
  ItemContainer,
} from "./MainMenu.styles";

type MenuItemConfig = {
  label: string;
  href: string;
  showAsActiveFor: Page[];
};
const buildVersion = process.env.REACT_APP_BUILD_VERSION;

const getMenuItems = (t: any): MenuItemConfig[] => {
  const items: MenuItemConfig[] = [
    {
      label: t("My orders"),
      href: createUrl(Page.ORDER_LIST),
      showAsActiveFor: [
        Page.ORDER_LIST,
        Page.VIEW_ORDER,
        Page.VIEW_ORDER_PROJECT,
      ],
    },
    {
      label: t("Nytt prosjekt"),
      href: createUrl(Page.CREATE_ORDER, { slug: "velgny" }),
      showAsActiveFor: [Page.CREATE_ORDER],
    },
    {
      label: t("Documentation"),
      href: createUrl(Page.DOCUMENTATION),
      showAsActiveFor: [Page.DOCUMENTATION],
    },
  ];
  return items;
};

type Props = {
  currentPage: Page;
  displayName?: string;
  user?: User;
};

export const MainMenu: FunctionComponent<Props> = ({
  currentPage,
  user,
  displayName,
}) => {
  const { t } = useTranslation();
  const items = user !== null ? getMenuItems(t) : [];
  const actions = useActions();
  const effects = useEffects();
  const state = useAppState();
  const { logout } = useAuth0();
  const ref = useRef();
  const [isOpen, toggleMenu] = useState(false);

  const isSmall = useMediaQuery(
    `(max-width: ${LayoutConstants.sidebarBreak}px)`
  );
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  useOnClickOutside(ref, () => {
    toggleMenu(!isOpen);
  });

  useEffect(() => {
    toggleMenu(false);
  }, [currentPage]);

  // Temporary menu while guest has no items present
  if (user === null) {
    return (
      <Container isOverlay={false}>
        <ItemContainer isOverlay={false}>
          <Item href="/">
            <img style={{ margin: "0.5rem" }} src={Logo} alt="logo" />
          </Item>
        </ItemContainer>
        <ItemContainer isOverlay={false}>
          <DropdownItem
            onClick={() => effects.router.navigateTo(createUrl(Page.LOGIN))}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.56799 19.2578C3.05072 21.2543 3.96711 23.0829 5.21215 24.6361C8.29813 22.3574 12.0524 21.0906 16.0001 21.0906C19.9477 21.0906 23.7019 22.3574 26.788 24.6361C28.033 23.0828 28.9494 21.2541 29.4322 19.2578C29.6858 18.2051 29.8181 17.1128 29.8181 16C29.8181 8.369 23.631 2.18188 16 2.18188C8.36897 2.18188 2.18185 8.369 2.18185 16C2.18185 17.1128 2.31412 18.2051 2.56778 19.2578H2.56799ZM25.2812 26.2384C22.6629 24.3715 19.4596 23.2724 16 23.2724C12.5404 23.2724 9.33706 24.3715 6.7188 26.2384C9.17204 28.4626 12.4273 29.8181 16 29.8181C19.5727 29.8181 22.8281 28.4626 25.2812 26.2384ZM16 32C7.16355 32 0 24.8364 0 16C0 7.16355 7.16355 0 16 0C24.8364 0 32 7.16355 32 16C32 24.8364 24.8364 32 16 32Z"
                fill="#430A03"
              />
              <path
                d="M16.0005 17.455C18.0091 17.455 19.6374 15.8268 19.6374 13.8181C19.6374 11.8094 18.0091 10.1811 16.0005 10.1811C13.9918 10.1811 12.3635 11.8094 12.3635 13.8181C12.3635 15.8268 13.9918 17.455 16.0005 17.455ZM16.0005 19.6369C12.7876 19.6369 10.1816 17.0309 10.1816 13.8181C10.1816 10.6052 12.7876 7.99927 16.0005 7.99927C19.2133 7.99927 21.8193 10.6052 21.8193 13.8181C21.8193 17.0309 19.2133 19.6369 16.0005 19.6369Z"
                fill="#430A03"
              />
            </svg>
          </DropdownItem>
        </ItemContainer>
      </Container>
    );
  }

  if (isSmall && !isOpen) {
    return (
      <BurgerBar>
        <img style={{ margin: "0.5rem" }} src={Logo} alt="logo" />
        <BurgerButton onClick={() => toggleMenu(!isOpen)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
        </BurgerButton>
      </BurgerBar>
    );
  }
  return (
    <Container isOverlay={isSmall}>
      {isSmall && (
        <BurgerBar>
          <img style={{ margin: "0.5rem" }} src={Logo} alt="logo" />
        </BurgerBar>
      )}

      <ItemContainer isOverlay={isSmall}>
        <Item href="/">
          <img style={{ margin: "0.5rem" }} src={Logo} alt="logo" />
          <span
            style={{
              position: "absolute",
              right: ".5rem",
              top: ".5rem",
              fontSize: ".5rem",
            }}
          >
            {displayName}
          </span>
        </Item>
      </ItemContainer>
      <ItemContainer isOverlay={isSmall}>
        {items.map((i, idx) => (
          <Item
            key={idx}
            href={i.href}
            isActive={i.showAsActiveFor.includes(currentPage)}
          >
            {i.label}
          </Item>
        ))}
        {state.mesterIdLogin && state.mesterIdCustomers.length > 1 ? (
          <Item
            href={createUrl(Page.CUSTOMER_SELECTOR)}
            isActive={currentPage === Page.CUSTOMER_SELECTOR}
          >
            Kundevelger
          </Item>
        ) : null}
        {user ? (
          <Item>
            <p
              onClick={() => {
                if (state.mesterIdLogin) {
                  actions.auth.logoutMesterId().then(() => {
                    logout({ returnTo: window.location.origin });
                  });
                } else {
                  actions.auth.logout();
                }
              }}
            >
              {t("Logout")}
            </p>
          </Item>
        ) : null}

        <DropdownItem>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
          >
            <path
              d="M2.56799 19.2578C3.05072 21.2543 3.96711 23.0829 5.21215 24.6361C8.29813 22.3574 12.0524 21.0906 16.0001 21.0906C19.9477 21.0906 23.7019 22.3574 26.788 24.6361C28.033 23.0828 28.9494 21.2541 29.4322 19.2578C29.6858 18.2051 29.8181 17.1128 29.8181 16C29.8181 8.369 23.631 2.18188 16 2.18188C8.36897 2.18188 2.18185 8.369 2.18185 16C2.18185 17.1128 2.31412 18.2051 2.56778 19.2578H2.56799ZM25.2812 26.2384C22.6629 24.3715 19.4596 23.2724 16 23.2724C12.5404 23.2724 9.33706 24.3715 6.7188 26.2384C9.17204 28.4626 12.4273 29.8181 16 29.8181C19.5727 29.8181 22.8281 28.4626 25.2812 26.2384ZM16 32C7.16355 32 0 24.8364 0 16C0 7.16355 7.16355 0 16 0C24.8364 0 32 7.16355 32 16C32 24.8364 24.8364 32 16 32Z"
              fill="#430A03"
            />
            <path
              d="M16.0005 17.455C18.0091 17.455 19.6374 15.8268 19.6374 13.8181C19.6374 11.8094 18.0091 10.1811 16.0005 10.1811C13.9918 10.1811 12.3635 11.8094 12.3635 13.8181C12.3635 15.8268 13.9918 17.455 16.0005 17.455ZM16.0005 19.6369C12.7876 19.6369 10.1816 17.0309 10.1816 13.8181C10.1816 10.6052 12.7876 7.99927 16.0005 7.99927C19.2133 7.99927 21.8193 10.6052 21.8193 13.8181C21.8193 17.0309 19.2133 19.6369 16.0005 19.6369Z"
              fill="#430A03"
            />
          </svg>

          {hover ? (
            <p style={{ position: "absolute", top: "7.5%" }}>
              Versjon: {buildVersion}
            </p>
          ) : null}
        </DropdownItem>
      </ItemContainer>
    </Container>
  );
};
