import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { LinkButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { useAuth0 } from "@auth0/auth0-react";

const buildVersion = process.env.REACT_APP_BUILD_VERSION;

export const LoginPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  // eslint-disable-next-line no-undef
  useEffect(() => {
    loginWithRedirect();
  }, []);
  // TODO: Sjekk om maxwidth kan ligge i form
  return (
    <Page title={t("Login")}>
      <LinkButton onClick={() => loginWithRedirect()}>
        Logg inn med MesterID
      </LinkButton>
      <p style={{ marginTop: "1rem" }}> Versjon: {buildVersion}</p>
    </Page>
  );
};
