import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { Select } from "../../components/Select/Select";
import Logo from "../../assets/img/Mellometasje.png";
import { OfferOptionType } from "../../types/OfferOption";
import { ExtendedPrecut } from "../../types/DraftOrder/ExtendedPrecut";
import { useAppState } from "../../overmind";
import { BlockButton } from "../../components/Buttons/Buttons.styles";

interface PrecutModalFormProps {
  setPlans: (plans: ExtendedPrecut[]) => void;
  plans: ExtendedPrecut[];
  setSelectedPlan: (plans: ExtendedPrecut) => void;
  selectedPlan: ExtendedPrecut | undefined;
  setFormActive: (active: boolean) => void;
}

const svills = [
  {
    label: "36mm",
    value: "36mm",
  },
  {
    label: "2x36mm",
    value: "2x36mm",
  },
  {
    label: "48mm",
    value: "48mm",
  },
  {
    label: "2x48mm",
    value: "2x48mm",
  },
];
const PrecutModalForm: FunctionComponent<PrecutModalFormProps> = ({
  setPlans,
  plans,
  selectedPlan,
  setSelectedPlan,
  setFormActive,
}) => {
  const { modals, offerOptions } = useAppState();

  const dimensions = offerOptions
    .filter((o) => o.type === OfferOptionType.PRECUT_DIMENSION)
    .map((o) => ({ label: o.description, value: o.description }));

  const onSubmit = (data: ExtendedPrecut) => {
    if (selectedPlan !== undefined) {
      const newPlans = plans.map((x) => {
        if (x.name === data.name) {
          return {
            ...x,
            bruttoVegghoyde: data.bruttoVegghoyde,
            hoydeOverkantApning: data.hoydeOverkantApning,
            dimensjonToppsvill: data.dimensjonToppsvill,
            dimensjonStender: data.dimensjonStender,
            dimensjonBunnsvill: data.dimensjonBunnsvill,
            klaringTilKarm: data.klaringTilKarm,
            biv1: data.biv1,
            dimToppsvill1: data.dimToppsvill1,
            dimBunnsvill1: data.dimBunnsvill1,
            biv2: data.biv2,
            dimToppsvill2: data.dimToppsvill2,
            dimBunnsvill2: data.dimBunnsvill2,
            biv3: data.biv3,
            dimToppsvill3: data.dimToppsvill3,
            dimBunnsvill3: data.dimBunnsvill3,
          };
        }
        return x;
      });
      setPlans(newPlans);
    } else {
      const newPlan = {
        ...data,
        name: `Plan ${plans.length + 1}`,
        projectId: modals.precutModal.projectId,
      };
      setPlans([...plans, newPlan]);
    }
    setSelectedPlan(undefined);
    setFormActive(false);
  };
  const onCancel = () => {
    setFormActive(false);
    setSelectedPlan(undefined);
  };
  const { register, handleSubmit } = useForm<ExtendedPrecut>({
    defaultValues: selectedPlan,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <div style={{ display: "flex" }}>
        <div>
          <input ref={register} type="hidden" name="projectId" />
          <input ref={register} type="hidden" name="name" />
          <FormInput
            label="Brutto vegghøyde"
            type="number"
            name="bruttoVegghoyde"
            suffix="mm"
            inputRef={register}
          />
          <FormInput
            label="Høyde overkant åpning"
            type="number"
            name="hoydeOverkantApning"
            suffix="mm"
            inputRef={register}
          />
          <Select
            name="dimensjonToppsvill"
            label="Dimensjon toppsvill"
            options={svills}
            inputRef={register}
          />
          <Select
            name="dimensjonStender"
            label="Dimensjon stender"
            options={dimensions}
            inputRef={register}
          />
          <Select
            name="dimensjonBunnsvill"
            label="Dimensjon bunnsvill"
            options={svills}
            inputRef={register}
          />
          <FormInput
            label="Klaring til karm"
            type="number"
            name="klaringTilKarm"
            suffix="mm"
            inputRef={register}
          />
        </div>
        <div>
          <img style={{ margin: "0.5rem" }} src={Logo} alt="logo" />
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "30%",
          }}
        >
          <BlockButton style={{ marginBottom: "2em" }} type="submit">
            {selectedPlan !== undefined
              ? ` Oppdater ${selectedPlan.name}`
              : `Legg til Plan ${plans.length + 1}`}
          </BlockButton>
          <BlockButton onClick={() => onCancel()} type="button">
            Avbryt
          </BlockButton>
        </div>
      </div>
      <div style={{ fontSize: "1.125rem", fontWeight: "bolder" }}>
        Bærende innervegger:
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignContent: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Select
            align
            selectWidth="12em"
            name="biv1"
            label="Dimensjon stender"
            options={dimensions}
            inputRef={register}
          />
          <Select
            selectWidth="12em"
            name="biv2"
            options={dimensions}
            inputRef={register}
          />
          <Select
            selectWidth="12em"
            name="biv3"
            options={dimensions}
            inputRef={register}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "4em",
          }}
        >
          <Select
            align
            selectWidth="12em"
            name="dimToppsvill1"
            label="Dimensjon toppsvill"
            options={svills}
            inputRef={register}
          />
          <Select
            selectWidth="12em"
            name="dimToppsvill2"
            options={svills}
            inputRef={register}
          />
          <Select
            selectWidth="12em"
            name="dimToppsvill3"
            options={svills}
            inputRef={register}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "4em",
          }}
        >
          <Select
            align
            selectWidth="12em"
            name="dimBunnsvill1"
            label="Dimensjon bunnsvill"
            options={svills}
            inputRef={register}
          />
          <Select
            selectWidth="12em"
            name="dimBunnsvill2"
            options={svills}
            inputRef={register}
          />
          <Select
            selectWidth="12em"
            name="dimBunnsvill3"
            options={svills}
            inputRef={register}
          />
        </div>
      </div>
    </form>
  );
};

export default PrecutModalForm;
