// eslint-disable-next-line no-shadow
export enum CMSArticles {
  DOCUMENTATION = 1402,
  ADMIN = 1403,
  WELCOME_LOGGED_IN_1 = 1404,
  WELCOME_GUEST_1 = 1405,
  WELCOME_LOGGED_IN_2 = 1406,
  WELCOME_GUEST_2 = 1407,
  NEW_USER_INFO = 1408,
  TECHNICAL_SERVICES = 1409,
  ATTACHMENT_INFO_OFFERS = 1410,
  ATTACHMENT_INFO_ORDERS = 1426,
  OFFER_SUMMARY = 1411,
  PRECUT_INFO = 1427,
  MAP_INFO = 1413,
  OFFER_SELECTION_HELPER = 1414,
  NOT_CALCULATED = 1424,
  AMOUNT_DAYS_NEW_OFFER = 1423,
  ORDER_NO_DRAWINGS = 1428,
  ORDER_SUMMARY_MULTIPLE = 1429,
  ORDER_SELECTION_HELPER = 1430,
  ORDER_SUMMARY = 1431,
}
export type CMSArticle = {
  title: string;
  content: string;
};
