/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";

type Props = {};
type State = {
  hasError: boolean;
};
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {}

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <h1>En feil oppstod. Prøv å logge inn på nytt.</h1>;
    }
    return children;
  }
}
