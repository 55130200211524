import React from "react";
import ReactDOM from "react-dom";
import "./normalize-tailwind.css";
import "./index.css";
// Overmind
import { createOvermind } from "overmind";
import { Provider } from "overmind-react";

import { Auth0Provider } from "@auth0/auth0-react";
import CacheBuster from "react-cache-buster";
import { config } from "./overmind/config";
import { App } from "./app/App";

import "./i18n";

import * as serviceWorker from "./serviceWorker";

import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { version } from "../package.json";

const overmind = createOvermind(config, {
  devtools: "127.0.0.1:3031",
});

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const isProduction = process.env.NODE_ENV === "production";

ReactDOM.render(
  <React.StrictMode>
    <CacheBuster currentVersion={version} isEnabled={isProduction}>
      <Provider value={overmind}>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          redirectUri={`${window.location.origin}/auth`}
          audience="mg.iam"
        >
          {/* @ts-ignore */}
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Auth0Provider>
      </Provider>
    </CacheBuster>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
