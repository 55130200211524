// eslint-disable-next-line no-shadow
export enum EmployeeMessageReferenceType {
  "ORDER" = 3,
  "ORDER_PROJECT" = 4,
  "CUSTOMER" = 5,
}
export type CreateEmployeeMessage = {
  employeeId: string;
  referenceId: string;
  referenceType: EmployeeMessageReferenceType;
  message?: string;
  loggedInEmail?: string;
};
