/* eslint-disable no-undef,no-param-reassign */
// @ts-nocheck
import { derived, IState } from "overmind";

export const createModals = <
  T extends {
    [name: string]: {
      state?: IState;
      result?: unknown;
    };
  }
>(
  modals: T
): {
  state: {
    current: keyof T | null;
  } & {
    [K in keyof T]: T[K]["state"] & { isCurrent: boolean };
  };
  actions: {
    [K in keyof T]: {
      open: ContextFunction<
        T[K]["state"] extends IState ? T[K]["state"] : void,
        T[K]["result"]
      >;
      close: ContextFunction<T[K]["result"], void>;
    };
  };
} => {
  function createModal(name, modal) {
    let resolver: ((res: T) => void) | null;

    const open = async ({ state }: Context, newState = {}) => {
      state.modals.current = name;

      Object.assign(state.modals[name], newState);

      return new Promise((resolve) => {
        resolver = resolve;
      });
    };

    const close = async ({ state }: Context, payload) => {
      state.modals.current = null;
      if (modal.state) {
        Object.keys(modal.state).forEach((stateKey) => {
          state.modals[name][stateKey] = modal.state[stateKey];
        });
      }
      if (resolver) {
        resolver(typeof payload === "undefined" ? modal.result : payload);
      }
    };

    return {
      state: {
        ...modal.state,
        isCurrent: derived(
          (_, root: Context["state"]) => root.modals.current === name
        ),
      },
      actions: {
        open,
        close,
      },
    };
  }

  return Object.keys(modals).reduce(
    (aggr, name) => {
      const modal = createModal(name, modals[name]);

      aggr.state[name] = modal.state;
      aggr.actions[name] = modal.actions;

      return aggr;
    },
    {
      state: {
        current: null,
      },
      actions: {},
    }
  ) as any;
};
