import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
type RowProps = {
  size?: number;
};
export const Row = styled("div")<RowProps>`
  display: flex;
  ${(props) => (props.size ? { flex: props.size } : null)};
  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }
`;
type ColProps = {
  size: number;
};
export const Col = styled("div")<ColProps>`
  flex: ${(props) => props.size};
  margin: 1rem;
`;
