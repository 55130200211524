import React, { FunctionComponent, useRef } from "react";
import { Backdrop, Container, InnerContainer, Wrapper } from "./Modal.styles";
import { useKeyDown } from "../../hooks/useKeyDown";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { Header } from "../../elements/Header/Header";

type Props = {
  children: any;
  isOpen: boolean;
  onClose: any;
  title?: string;
};

const Modal: FunctionComponent<Props> = ({
  title,
  children,
  isOpen,
  onClose,
}) => {
  const ref = useRef();
  const scrollRef = useRef<HTMLDivElement>();

  useKeyDown(() => {
    if (ref.current) {
      onClose();
    }
  }, [27]);

  useOnClickOutside(ref, () => {
    onClose();
  });

  if (!isOpen) {
    return null;
  }
  return (
    <Wrapper>
      <Backdrop />
      <Container id="mainmodal" ref={ref}>
        <InnerContainer ref={scrollRef}>
          {title && <Header>{title}</Header>}
          {children}
        </InnerContainer>
      </Container>
    </Wrapper>
  );
};

export default Modal;
