import React, { FunctionComponent } from "react";
import { Label } from "@frontend/common/src/elements/Label/Label";
import { FormControl, Error } from "../../elements/FormControl/FormControl";
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
} from "./Checkbox.styles";

type Props = {
  checked?: boolean;
  name?: string;
  label?: string;
  error?: string;
  required?: any;
  inputRef?: any;
  value?: any;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void;
};
// https://codesandbox.io/s/building-a-checkbox-component-with-react-and-styled-components-forked-fc4rc?file=/src/index.js:200-257
export const Checkbox: FunctionComponent<Props> = ({
  checked,
  label,
  error,
  inputRef,
  ...props
}) => {
  return (
    <FormControl>
      <CheckboxContainer>
        <Label style={{ marginLeft: "0rem" }}>
          <HiddenCheckbox checked={checked} {...props} ref={inputRef} />
          <StyledCheckbox checked={checked} error={!!error}>
            <Icon viewBox="0 0 24 18">
              <path
                d="M0 9.91357L2.60829 6.52202L9.39163 12.2615L20.3488 0L23.4791 2.86985L9.65201 18L0 9.91357Z"
                fill="#CA0000"
              />
            </Icon>
          </StyledCheckbox>
          {label || null}
        </Label>
        {error ? <Error>{error}</Error> : null}
      </CheckboxContainer>
    </FormControl>
  );
};
