import styled from "styled-components";
import { PreTreColors } from "../../styles/constants";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.25s ease-in;
  z-index: 30;
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${PreTreColors.redbrown};
  opacity: 0.3;
  z-index: -1;
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
`;

export const Container = styled.div`
  animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  max-width: 80vw;
  max-height: 90vh;
  background: ${PreTreColors.weakBrite};
  z-index: 4;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 8px;
  overflow: hidden;

  box-sizing: content-box;

  @media (min-width: 320px) {
    min-width: 320px;
  }

  @media (max-width: 320px) {
    margin: 0 5px;
    padding: 36px 15px;
    width: inherit;
  }
`;

export const InnerContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  max-height: calc(95vh);
  max-width: inherit;
`;
