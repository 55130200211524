import styled from "styled-components";
import { baseInputStyles } from "../../styles/baseInput";

type Props = {
  error?: boolean;
  selectWidth?: string;
  readonly?: boolean;
};

export const SelectElement = styled("select")<Props>`
  ${baseInputStyles}
  font-size: 1rem;
  padding: 0.7rem;
  width: ${({ selectWidth }) => selectWidth || "100%"};
  appearance: none;
  font-weight: 700;
  background: #f4f4f4
    url("data:image/svg+xml;utf8,<svg width='14' height='19' viewBox='0 0 14 19' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8.04526 0L8.04526 16.193L5.95613 16.193L5.95613 -9.13186e-08L8.04526 0Z' fill='%23CA0000'/><path d='M-6.39614e-08 11.7015L1.46327 10.2383L7 15.776L12.5367 10.2383L14 11.7015L6.99997 18.7006L-6.39614e-08 11.7015Z' fill='%23CA0000'/></svg>")
    no-repeat;
  background-position: right 5px top 50%;
  pointer-events: ${(props: Props) => (props.readonly ? "none" : "")};

  &:disabled {
    background: #ddd;
    border: 1px solid #b7b7b7;
  }
`;
