/* eslint-disable no-param-reassign */
import fileDownload from "js-file-download";
import { UserType } from "@frontend/common/src/types/User";
import { Page } from "@frontend/common/src/types/Page";
import { Context } from "..";
import { OrderFilter, OrderSortOption } from "../../types/Order";
import { Document } from "../../types/Document";
import { createUrl } from "../../app/pages";
import { DraftOrder } from "../../types/DraftOrder/DraftOrder";
import { CreateOrderSteps } from "../../types/CreateOrderSteps";
import { FileUploadError } from "../../errors/FileUploadError";
import { DraftOrderSubProject } from "../../types/DraftOrder/DraftOrderSubProject";

export const changeOrderFilter = async (
  { state, effects }: Context,
  change: OrderFilter
) => {
  state.orders.orderFilter = {
    ...state.orders.orderFilter,
    ...change,
  };

  effects.router.navigateTo(
    createUrl(Page.ORDER_LIST, { side: state.orders.currentPage })
  );
};

export const clearOrderFilter = async ({ state }: Context) => {
  state.orders.currentPage = 1;
  state.orders.orderFilter = {
    hideDeliveredOrders: true,
    deliveryYear: null,
    showOnlyMyOrdersAndProjects: state.auth.user.type === UserType.EMPLOYEE,
  };
};

export const downloadDocument = async (
  { effects }: Context,
  payload: docDownload
) => {
  const filename = `${payload.file.description}`;
  const contents = await effects.api.order.getDocument(
    payload.file.customerId,
    payload.file.orderId,
    filename
  );
  fileDownload(contents, payload.file.description);
};

export const setSortOption = async (
  { state }: Context,
  option: OrderSortOption
) => {
  state.orders.currentOrderSortOption = option;
};
export type docDownload = {
  file: Document;
};

export const openDocument = async (
  { effects }: Context,
  payload: docDownload
) => {
  const filename = `${payload.file.description}`;
  const contents = await effects.api.order.getDocument(
    payload.file.customerId,
    payload.file.orderId,
    filename
  );
  const fileX = new Blob([contents], { type: "application/pdf;base64" });
  const fileURL = URL.createObjectURL(fileX);
  window.open(fileURL);
};

export const getOrderAutofill = async (
  { state, effects }: Context,
  params: { customerId: string; offerId: string }
) => {
  const data = await effects.api.order.getOrderFromOfferId(
    params.customerId,
    params.offerId
  );
  if (data) {
    state.orders.autofill = {
      ...data,
      offerId: params.offerId,
    } as DraftOrder;
    state.orders.currentNewOrder = {
      ...state.orders.currentNewOrder,
    };
  }
};

export const commitDraftOrder = async (
  { state, effects }: Context,
  payload: {
    customerId: string;
    order: DraftOrder;
    offerId?: string;
  }
) => {
  try {
    const orderId = await effects.api.order.commitDraftOrder(
      payload.customerId,
      payload.order,
      state.auth.user.username,
      payload?.offerId
    );
    state.orders.currentNewOrder.orderId = orderId;
    effects.router.navigateTo(
      createUrl(Page.CREATE_ORDER, { slug: "kvittering" })
    );
  } catch (error) {
    effects.toast(error as string, 5000);
  }
};

export const commitSubProjs = async (
  { state, effects }: Context,
  payload: {
    customerId: string;
    order: DraftOrder;
    projectId: string;
    offerId?: string;
  }
) => {
  try {
    await effects.api.order.commitSubProjects(
      payload.customerId,
      payload.order,
      state.auth.user.username,
      payload.projectId,
      payload?.offerId
    );
    const files = effects.localFileStorage.get();

    if (files && files.length) {
      try {
        await effects.api.order.addFiles(
          payload.projectId,
          files,
          payload.customerId
        );
        effects.localFileStorage.set(undefined);
      } catch (error) {
        effects.localFileStorage.set(undefined);
        throw new FileUploadError();
      }
    }
  } catch (e) {
    if (e instanceof FileUploadError) {
      effects.toast("Tilbud opprettet, men ikke alle filer ble sendt.", 5000);
    }
  }
};

export const updateDraftOrder = ({ state }: Context, payload: DraftOrder) => {
  state.orders.currentNewOrder = {
    ...state.orders.currentNewOrder,
    ...payload,
  };
};
export const setSubProjectsOnDraftOrder = (
  { state }: Context,
  subProjects: DraftOrderSubProject[]
) => {
  state.orders.currentNewOrder = {
    ...state.orders.currentNewOrder,
    subProjects,
  };
  state.orders.autofill = {
    ...state.orders.autofill,
    subProjects: [],
  };
};

export const setQuotationOnDraftOrder = (
  { state }: Context,
  payload: { isQuotation: boolean; quickChoice?: string }
) => {
  state.orders.currentNewOrder = {
    ...state.orders.currentNewOrder,
    quotation: payload.isQuotation,
  };
  state.orders.isQuote = payload.isQuotation;
  state.orders.quickChoice = payload.quickChoice;
};

export const setOrderConverted = ({ state }: Context, payload: boolean) => {
  state.orders.orderConverted = payload;
};

export const setDraftOfferFiles = ({ effects }: Context, files: File[]) => {
  effects.localFileStorage.set(files);
};

export const cancelCreateOrder = ({ effects, state }: Context) => {
  state.orders.currentNewOrder = {} as DraftOrder;
  state.orders.autofill = null;
  state.orders.lastCompletedOrderStep = CreateOrderSteps.CUSTOMER_INFO;
  effects.router.navigateTo(createUrl(Page.HOME));
};

export const clearAutofillAndDraftOrder = ({ state }: Context) => {
  state.orders.currentNewOrder = {} as DraftOrder;
  state.orders.autofill = null;
  state.orders.lastCompletedOrderStep = CreateOrderSteps.PROJECT_TYPE;
};
