import styled from "styled-components";
import BackgroundImage from "../../assets/img/banner.jpg";

export const Page = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const Shader = styled("div")`
  width: 100%;
  height: calc(100vw * 0.22);
  min-height: 430px;
  content: "";
  position: relative;
  left: 0;
  top: 0;
  background: rgba(67, 10, 3, 0.5);
`;
export const BannerImage = styled("div")`
  width: 100%;
  height: calc(100vw * 0.22);
  min-height: 430px;
  background: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;
export const Container = styled("div")`
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;
export const ItemContainer = styled("div")`
  display: flex;
  height: calc(100vw * 0.22);
  min-height: 430px;
`;
export const VerticalCenterBox = styled("div")`
  align-self: center;
`;
export const SubText = styled("h4")`
  margin-bottom: 26px;
  font-size: 1.5rem;
  color: white;
  font-weight: 400;
`;
export const BtnWrapper = styled("div")`
  width: 100%;
  max-width: 200px;
`;
