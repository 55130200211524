/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextArea } from "@frontend/common/src/components/TextArea/TextArea";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import * as validators from "@frontend/common/src/validators";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios, { CancelTokenSource } from "axios";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { useActions, useAppState, useEffects } from "../../../overmind";

import { Select } from "../../../components/Select/Select";

import { OfferOptionType } from "../../../types/OfferOption";
import { FormProps } from "./RoofForm";
import { BlockButton } from "../../../components/Buttons/Buttons.styles";
import {
  DraftOrderSubProject,
  PrecutOpening,
} from "../../../types/DraftOrder/DraftOrderSubProject";
import { makeId } from "../../../helpers/GenerateId";
import { CalculatePriceModal } from "../../../modals/CalculatePriceModal/CalculatePriceModal";
import {
  Address,
  Calculation,
  CalculationPayload,
} from "../../../types/DraftOrder/Calculation";
import { CalculationPriceAndError } from "./CalculationPriceAndError";
import { CalcConditions } from "./CalcConditions";
import Tooltip from "./Tooltip";

import { Header } from "../../../elements/Header/Header";
import PrecutOpeningRow from "./PrecutOpening";

const precut = yup.object().shape({
  // dimensions: validators.string.required(),
  comment: validators.string,
});

export const ExtendedPrecutForm: FunctionComponent<FormProps> = ({
  setSubProjects,
  setActiveForm,
  subProjects,
  selectedSubProject,
  setSelectedSubProject,
  autoFill,
}) => {
  const { t } = useTranslation();
  const { offerOptions, modals, customer, orders } = useAppState();
  const actions = useActions();
  const effects = useEffects();
  const cancelToken: React.MutableRefObject<CancelTokenSource | null> =
    useRef(null);
  const [calc, setCalc] = useState<Calculation | undefined>(undefined);
  const [priceDisabled, setPriceDisabled] = useState<boolean>(true);
  const [showPriceButton, setShowPriceButton] = useState<boolean>(false);
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const [openings, setOpenings] = useState<PrecutOpening[]>([
    {
      id: makeId(4),
      bearing: false,
      height: 400,
      width: 400,
      quantity: 0,
    },
  ]);
  const cancelCalculation = () => {
    if (cancelToken.current != null) {
      cancelToken.current.cancel();
    }
    // TODO remove
    setPriceDisabled(false);
    setShowPriceButton(false);
    actions.modals.calculatePriceModal.close();
  };

  const onSubmit = (data: DraftOrderSubProject) => {
    if (data.projectId === undefined || data.projectId.length < 1) {
      // eslint-disable-next-line no-param-reassign
      data.projectId = makeId(8);
    }
    if (calc !== undefined) {
      // eslint-disable-next-line no-param-reassign
      data.conditions = calc.conditions;
      // eslint-disable-next-line no-param-reassign
      data.preconditions = calc.preconditions;
      // eslint-disable-next-line no-param-reassign
      data.priceConditions = calc.priceConditions;
    }
    // eslint-disable-next-line no-param-reassign
    data.precutOpenings = openings;
    if (selectedSubProject !== undefined) {
      const newProjs = subProjects.map((x) => {
        if (x.projectId === data.projectId) {
          return {
            ...x,
            comment: data.comment,
            dimensionOuter:
              data.dimensionOuter ?? selectedSubProject.dimensionOuter,
            lengthOuter: data.lengthOuter ?? selectedSubProject.lengthOuter,
            numberOfCornersOuter:
              data.numberOfCornersOuter ??
              selectedSubProject.numberOfCornersOuter,
            dimensionInner:
              data.dimensionInner ?? selectedSubProject.dimensionInner,
            lengthInner: data.lengthInner ?? selectedSubProject.lengthInner,
            numberOfCornersInner:
              data.numberOfCornersInner ??
              selectedSubProject.numberOfCornersInner,
            wallHeight: data.wallHeight ?? selectedSubProject.wallHeight,
            precutOpenings: data.precutOpenings,
            price: data.price ?? selectedSubProject.price,
            calculationId:
              data.calculationId ?? selectedSubProject.calculationId,
            priceConditions:
              data.priceConditions ?? selectedSubProject.priceConditions,
            conditions: data.conditions ?? selectedSubProject.conditions,
            preconditions:
              data.preconditions ?? selectedSubProject.preconditions,
            roofRef: data.roofRef ?? selectedSubProject.roofRef,
            dimensionGavl:
              data.dimensionGavl ?? selectedSubProject.dimensionGavl,
            numberOfGavl: data.numberOfGavl ?? selectedSubProject.numberOfGavl,
          };
        }
        return x;
      });
      setSubProjects(newProjs);
    } else {
      setSubProjects([...subProjects, data]);
    }
    setActiveForm("");
    setSelectedSubProject(undefined);
  };
  const onCancel = () => {
    setActiveForm("");
    setSelectedSubProject(undefined);
  };
  const dimensions = offerOptions
    .filter(
      (o) =>
        o.type === OfferOptionType.PRECUT_DIMENSION &&
        !o.description.includes("Annet")
    )
    .map((o) => ({ label: o.description, value: o.id }));
  const { register, handleSubmit, errors, setValue, getValues } =
    useForm<DraftOrderSubProject>({
      resolver: yupResolver(precut),
      defaultValues: selectedSubProject,
    });
  useEffect(() => {
    if (selectedSubProject) {
      if (selectedSubProject.price) {
        setCalc({
          price: selectedSubProject.price,
          uid: selectedSubProject.calculationId,
          conditions: selectedSubProject.conditions ?? [],
          preconditions: selectedSubProject.preconditions ?? [],
          specifications: [],
          freight: selectedSubProject.freight,
          priceConditions: selectedSubProject.priceConditions ?? [],
        });
      }
      if (selectedSubProject.precutOpenings !== undefined) {
        setOpenings(selectedSubProject.precutOpenings);
      }
    }
  }, [selectedSubProject]);
  const calclulatePrice = () => {
    actions.modals.calculatePriceModal.open();
    cancelToken.current = axios.CancelToken.source();
    const formValues = getValues([
      "projectId",
      "numberOfTrusses",
      "angle",
      "workingWidth",
      "roofType",
      "rangeDistance",
      "roofingType",
      "protrusion",
      "roomHeight",
      "roomWidth",
    ]);
    const uid =
      formValues.projectId !== null && formValues.projectId.length > 0
        ? formValues.projectId
        : makeId(4);
    const { currentNewOrder } = orders;
    const address: Address = {
      street: currentNewOrder.deliveryAddress,
      height: Number(currentNewOrder.mamsl),
      city: currentNewOrder.city,
      zipCode: currentNewOrder.zipCode,
      gardsnr:
        currentNewOrder.farmNumber !== null
          ? currentNewOrder.farmNumber.toString()
          : null,
      bruksnr:
        currentNewOrder.subFarmNumber !== null
          ? currentNewOrder.subFarmNumber.toString()
          : null,
    };
    const body: CalculationPayload = {
      uid,
      projectType: "Takstol",
      buildingType:
        currentNewOrder.buildingType.length > 0
          ? currentNewOrder.buildingType
          : null,
      CustomerId: customer.id,
      deliveryterms: currentNewOrder.deliveryTerms,

      address,
    };
    effects.api.order
      .calculateRoof(body, cancelToken.current)
      .then((data) => {
        setCalc(data);
        setValue("price", data.price);
        setValue("calculationId", data.uid);
        setValue("freight", data.freight);
        cancelToken.current = null;
        actions.modals.calculatePriceModal.close();
      })
      .catch(() => {
        effects.toast("Noe gikk galt, vennligst prøv igjen senere", 5000);
        cancelToken.current = null;
        actions.modals.calculatePriceModal.close();
      });
  };
  const toolTipContent = () => {
    return <div />;
  };
  const changeOpening = (id: string, field: string, value: any) => {
    const op = openings.map((x) => {
      if (x.id === id) {
        return { ...x, [field]: value };
      }
      return x;
    });
    setOpenings(op);
  };
  const deleteOpening = (id: string) => {
    const op = openings.filter((x) => x.id !== id);
    setOpenings(op);
  };
  const roofs = subProjects
    .filter((x) => x.type === "extendedRoof")
    .map((o) => ({
      label: o.comment.length > 0 ? o.comment : "Tak",
      value: o.projectId,
    }));
  const addOpening = () => {
    const opening: PrecutOpening = {
      id: makeId(4),
      bearing: false,
      height: 400,
      width: 400,
      quantity: 0,
    };
    setOpenings([...openings, opening]);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <div>
          <input
            ref={register}
            type="hidden"
            value="extendedPrecut"
            name="type"
          />
          <input ref={register} type="hidden" name="projectId" />
          <input ref={register} type="hidden" name="price" />
          <input ref={register} type="hidden" name="calculationId" />
          <input ref={register} type="hidden" name="freight" />
          {subProjects.some(
            (x) => x.calculationId !== undefined && x.type === "extendedRoof"
          ) ? (
            <div>
              <Header type="secondary">Gavl</Header>
              <Select
                name="roofRef"
                label="Velg tak"
                options={roofs}
                inputRef={register}
                noDefault
                error={errors.dimensions?.message}
                disabled={
                  autoFill &&
                  selectedSubProject.dimensions !== null &&
                  selectedSubProject.dimensions.length > 0
                }
              />
              <Select
                name="dimensionGavl"
                label="Dimensjon precut"
                options={dimensions}
                inputRef={register}
                error={errors.dimensions?.message}
                disabled={
                  autoFill &&
                  selectedSubProject.dimensions !== null &&
                  selectedSubProject.dimensions.length > 0
                }
              />
              <FormInput
                label="Antall gavler"
                type="number"
                name="numberOfGavl"
                inputRef={register}
                readonly={calc !== undefined}
                disabled={autoFill}
                error={errors.angle?.message}
              />
            </div>
          ) : null}
          <Header type="secondary">Precut</Header>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "45%" }}>
              <Select
                name="dimensionOuter"
                label="Dimensjon yttervegg"
                options={dimensions}
                inputRef={register}
                error={errors.dimensions?.message}
                required
                disabled={
                  autoFill &&
                  selectedSubProject.dimensions !== null &&
                  selectedSubProject.dimensions.length > 0
                }
              />
              <FormInput
                label="Total vegglengde yttervegg"
                type="number"
                name="lengthOuter"
                suffix="m"
                inputRef={register}
                readonly={calc !== undefined}
                disabled={autoFill}
                error={errors.angle?.message}
              />
              <FormInput
                label="Antall ender / hjørner (yttervegg)"
                type="number"
                name="numberOfCornersOuter"
                inputRef={register}
                readonly={calc !== undefined}
                disabled={autoFill}
                error={errors.angle?.message}
              />
            </div>
            <div style={{ width: "45%" }}>
              <Select
                name="dimensionInner"
                label="Dimensjon innervegg"
                options={dimensions}
                inputRef={register}
                error={errors.dimensions?.message}
                required
                disabled={
                  autoFill &&
                  selectedSubProject.dimensions !== null &&
                  selectedSubProject.dimensions.length > 0
                }
              />
              <FormInput
                label="Total vegglengde innervegg"
                type="number"
                name="lengthInner"
                suffix="m"
                inputRef={register}
                readonly={calc !== undefined}
                disabled={autoFill}
                error={errors.angle?.message}
              />
              <FormInput
                label="Antall ender / hjørner (innervegg)"
                type="number"
                name="numberOfCornersInner"
                inputRef={register}
                readonly={calc !== undefined}
                disabled={autoFill}
                error={errors.angle?.message}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "2rem",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <FormInput
              label="Vegghøyde"
              type="number"
              name="wallHeight"
              suffix="mm"
              inputRef={register}
              readonly={calc !== undefined}
              disabled={autoFill}
              error={errors.angle?.message}
            />

            <BlockButton
              style={{
                width: "12em",
                fontSize: "1rem",
                alignSelf: "center",
                marginTop: "2rem",
              }}
              disabled={openings.length >= 6}
              type="button"
              onClick={() => addOpening()}
            >
              Legg til ny åpning
            </BlockButton>
          </div>
          {openings.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "2rem",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <span style={{ width: "5rem" }} />
              <span style={{ width: "8rem" }}>Antall åpninger</span>
              <span style={{ width: "8rem" }}>Bredde(mm)</span>
              <span style={{ width: "8rem" }}>Høyde(mm)</span>
              <span>Bærende</span>
              <span />
            </div>
          ) : null}
          {openings.map((x, idx) => {
            return (
              <PrecutOpeningRow
                key={x.id}
                opening={x}
                changeOpening={changeOpening}
                deleteOpening={deleteOpening}
                idx={idx}
              />
            );
          })}
          <TextArea
            label={t("Kommentar")}
            name="comment"
            rows={3}
            inputRef={register}
            placeholder={t("Write other required info here")}
            defaultValue={errors.comment?.message}
          />
          {/* calc === undefined && showPriceButton ? (
            <div
              style={{
                display: "inline",
                position: "relative",
              }}
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
            >
              <Tooltip show={showToolTip}>{toolTipContent()}</Tooltip>
              {<BlockButton
                  style={{
                    marginRight: "2em",
                    backgroundColor: priceDisabled ? "gray" : "green",
                    width: "12em",
                  }}
                  onClick={() => calclulatePrice()}
                  type="button"
                  disabled={priceDisabled}
              >
                Beregn pris
              </BlockButton> }
            </div>
          ) : calc ? (
            <BlockButton
              style={{
                marginRight: "2em",
                backgroundColor: "green",
                width: "12em",
              }}
              onClick={() => setCalc(undefined)}
              type="button"
            >
              Rediger
            </BlockButton>
          ) : null */}
          <CMSContent contentId={1475} />
          <CMSContent contentId={CMSArticles.PRECUT_INFO} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "576px" }}>
            <CalculationPriceAndError calc={calc} />
          </div>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "flex-end",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: "auto",
            }}
          >
            <BlockButton
              style={{ marginRight: "2em", width: "12em", fontSize: "1rem" }}
              type="submit"
            >
              {selectedSubProject !== undefined ? "Oppdater" : "Legg til"}
            </BlockButton>
            <BlockButton
              style={{ width: "12em", fontSize: "1rem" }}
              onClick={() => onCancel()}
              type="button"
            >
              Avbryt
            </BlockButton>
          </div>
          <CalcConditions calc={calc} />
        </div>
      </div>
      {modals.calculatePriceModal.isCurrent ? (
        <CalculatePriceModal cancelCalculation={cancelCalculation} />
      ) : null}
    </form>
  );
};
