import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Grid, Row, Col } from "@frontend/common/src/elements/Grid/Grid";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import * as validators from "@frontend//common/src/validators";
import { BlockButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { Customer } from "@frontend/common/src/types/Customer";
import { useActions, useAppState } from "../../../../overmind";

const validationSchema = yup.object().shape({
  address: validators.address,
  zipcode: validators.zipcode,
  email: validators.requiredEmail,
  drawingsEmail: validators.email,
  orderConfimEmail: validators.email,
  orderConfrimEmailAlt: validators.email,
});

export const CompanyForm: FunctionComponent = () => {
  const actions = useActions();
  const state = useAppState();

  const { register, handleSubmit, errors } = useForm<Customer>({
    resolver: yupResolver(validationSchema),
    defaultValues: state.customer,
  });

  const onSubmitData = (data: Customer) => {
    // eslint-disable-next-line no-param-reassign
    data.id = state.customer.id;
    actions.changeCustomer(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <Grid>
        <Row>
          <Col size={1}>
            <FormInput
              required
              label="Kundenavn"
              type="text"
              name="name"
              readonly
              inputRef={register}
              error={errors.name?.message}
            />
            <FormInput
              required
              label="Adresse"
              type="text"
              name="address"
              inputRef={register}
              error={errors.address?.message}
            />
            <FormInput
              required
              label="Postnummer"
              type="text"
              name="zipcode"
              inputRef={register}
              error={errors.zipcode?.message}
            />
            <FormInput
              required
              label="Sted"
              type="text"
              name="city"
              readonly
              inputRef={register}
              error={errors.city?.message}
            />
            <FormInput
              required
              label="Epost"
              type="text"
              name="email"
              inputRef={register}
              error={errors.email?.message}
            />
          </Col>
          <Col size={1}>
            <FormInput
              label="Epost tegninger"
              type="text"
              name="drawingsEmail"
              inputRef={register}
              error={errors.drawingsEmail?.message}
            />
            <FormInput
              label="Epost ordrebekreftelse"
              type="text"
              name="orderConfimEmail"
              inputRef={register}
              error={errors.orderConfimEmail?.message}
            />
            <FormInput
              label="Epost ordresbekreftelse"
              type="text"
              name="orderConfrimEmailAlt"
              inputRef={register}
              error={errors.orderConfrimEmailAlt?.message}
            />
          </Col>
        </Row>
      </Grid>

      <div style={{ display: "flex" }}>
        <BlockButton type="submit">Lagre endringer</BlockButton>
      </div>
    </form>
  );
};
