import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Direction } from "../../Direction";

export const Wrapper = styled.svg`
  vertical-align: middle;
  margin-left: 1rem;
  display: inline-block;
`;

const down = (
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.956859 12.2062C1.88121 13.2401 2.80212 14.2706 3.72654 15.3046C3.85834 15.4535 3.98928 15.5981 4.1211 15.747C4.42322 16.0843 5.05912 16.0843 5.36126 15.747C6.28561 14.7131 7.20652 13.6827 8.13094 12.6487C8.26274 12.4998 8.39369 12.3552 8.5255 12.2062C8.83189 11.8647 8.88925 11.299 8.5255 10.9661C8.19257 10.6597 7.61399 10.6023 7.28533 10.9661C6.36098 12 5.44007 13.0304 4.51566 14.0644C4.38385 14.2133 4.25291 14.358 4.1211 14.5069H5.36126C4.43691 13.473 3.516 12.4425 2.59158 11.4085C2.45978 11.2596 2.32884 11.115 2.19702 10.9661C1.89063 10.6246 1.26327 10.6288 0.956859 10.9661C0.623932 11.3298 0.632477 11.8374 0.956859 12.2062Z"
      fill="#CA0000"
    />
    <path
      d="M5.61958 14.9578V10.2992V2.88476V1.19784C5.61958 0.737376 5.21648 0.29917 4.74317 0.321431C4.26987 0.343683 3.86676 0.707424 3.86676 1.19784V5.8564V13.2708V14.9578C3.86676 15.4182 4.26987 15.8564 4.74317 15.8342C5.21648 15.8119 5.61958 15.4482 5.61958 14.9578Z"
      fill="#CA0000"
    />
  </svg>
);

const up = (
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.49193 5.03389C2.41628 3.99999 3.33719 2.96953 4.26161 1.93556C4.39341 1.78664 4.52435 1.64201 4.65617 1.49308H3.416C4.34035 2.52698 5.26126 3.55744 6.18568 4.59141C6.31748 4.74033 6.44843 4.88497 6.58024 5.03389C6.88663 5.37538 7.51399 5.37111 7.8204 5.03389C8.15763 4.66586 8.14906 4.16175 7.8204 3.79373C6.89605 2.75982 5.97514 1.72936 5.05073 0.695394C4.91892 0.546472 4.78798 0.40184 4.65617 0.252916C4.35405 -0.0843053 3.71814 -0.0843053 3.416 0.252916C2.49165 1.28682 1.57074 2.31728 0.646324 3.35125C0.51452 3.50017 0.383576 3.6448 0.251764 3.79373C-0.0546293 4.13522 -0.11199 4.70094 0.251764 5.03389C0.589839 5.34114 1.1684 5.40192 1.49193 5.03389Z"
      fill="#CA0000"
    />
    <path
      d="M3.16174 1.04232V5.70089V13.1153V14.8022C3.16174 15.2627 3.56485 15.7009 4.03815 15.6786C4.51146 15.6564 4.91457 15.2927 4.91457 14.8022V10.1437V2.72924V1.04232C4.91457 0.581858 4.51146 0.143653 4.03815 0.165914C3.56485 0.188166 3.16174 0.551907 3.16174 1.04232Z"
      fill="#CA0000"
    />
  </svg>
);
const upDown = (
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.49193 5.03389C2.41628 3.99999 3.33719 2.96953 4.26161 1.93556C4.39341 1.78664 4.52435 1.64201 4.65617 1.49308H3.416C4.34035 2.52698 5.26126 3.55744 6.18568 4.59141C6.31748 4.74033 6.44843 4.88497 6.58024 5.03389C6.88663 5.37538 7.51399 5.37111 7.8204 5.03389C8.15763 4.66586 8.14906 4.16175 7.8204 3.79373C6.89605 2.75982 5.97514 1.72936 5.05073 0.695394C4.91892 0.546472 4.78798 0.40184 4.65617 0.252916C4.35405 -0.0843053 3.71814 -0.0843053 3.416 0.252916C2.49165 1.28682 1.57074 2.31728 0.646324 3.35125C0.51452 3.50017 0.383576 3.6448 0.251764 3.79373C-0.0546293 4.13522 -0.11199 4.70094 0.251764 5.03389C0.589839 5.34114 1.1684 5.40192 1.49193 5.03389Z"
      fill="#CA0000"
    />
    <path
      d="M3.16174 1.04232V5.70089V13.1153V14.8022C3.16174 15.2627 3.56485 15.7009 4.03815 15.6786C4.51146 15.6564 4.91457 15.2927 4.91457 14.8022V10.1437V2.72924V1.04232C4.91457 0.581858 4.51146 0.143653 4.03815 0.165914C3.56485 0.188166 3.16174 0.551907 3.16174 1.04232Z"
      fill="#CA0000"
    />
    <path
      d="M6.95686 14.2062C7.88121 15.2401 8.80212 16.2706 9.72654 17.3046C9.85834 17.4535 9.98928 17.5981 10.1211 17.747C10.4232 18.0843 11.0591 18.0843 11.3613 17.747C12.2856 16.7131 13.2065 15.6827 14.1309 14.6487C14.2627 14.4998 14.3937 14.3552 14.5255 14.2062C14.8319 13.8647 14.8893 13.299 14.5255 12.9661C14.1926 12.6597 13.614 12.6023 13.2853 12.9661C12.361 14 11.4401 15.0304 10.5157 16.0644C10.3839 16.2133 10.2529 16.358 10.1211 16.5069H11.3613C10.4369 15.473 9.516 14.4425 8.59158 13.4085C8.45978 13.2596 8.32884 13.115 8.19702 12.9661C7.89063 12.6246 7.26327 12.6288 6.95686 12.9661C6.62393 13.3298 6.63248 13.8374 6.95686 14.2062Z"
      fill="#CA0000"
    />
    <path
      d="M11.6196 16.9578V12.2992V4.88476V3.19784C11.6196 2.73738 11.2165 2.29917 10.7432 2.32143C10.2699 2.34368 9.86676 2.70742 9.86676 3.19784V7.8564V15.2708V16.9578C9.86676 17.4182 10.2699 17.8564 10.7432 17.8342C11.2165 17.8119 11.6196 17.4482 11.6196 16.9578Z"
      fill="#CA0000"
    />
  </svg>
);

type Props = {
  direction: Direction;
};
export const SortIcon: FunctionComponent<Props> = ({ direction }) => {
  return (
    <Wrapper
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {direction === "ascending" ? down : null}
      {direction === "descending" ? up : null}
      {direction === "initial" ? upDown : null}
    </Wrapper>
  );
};
