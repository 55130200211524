import styled, { css } from "styled-components";

type Props = {
  fullHeight?: boolean;
};
const fullHeightStyle = css`
  flex-grow: 1;
`;
export const VBox = styled("div")<Props>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${(props: Props) => props.fullHeight && fullHeightStyle}
`;
