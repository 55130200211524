import styled from "styled-components";
import { PreTreColors } from "../../styles/constants";

type Props = {
  theme?: string;
  type?: string;
};

export const Heading = styled("h1")<Props>`
  font-size: ${(props: Props) =>
    props.type === "secondary" ? "1.25rem" : "2.625rem"};
  font-weight: 700;
  color: ${(props: Props) =>
    props.theme === "light" ? "white" : PreTreColors.beis};
  font-family: "Kumbh Sans Normal", sans-serif;
  line-height: normal;
`;
