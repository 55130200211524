import { CMSArticle } from "@frontend/common/src/types/CMSArticle";

export const fetchCms = (
  url: string
): Promise<{ [index: number]: CMSArticle }> => {
  return fetch(url)
    .then((r) => r.text())
    .then((r) => {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(r, "text/xml");
      const nodes = xmlDoc.evaluate(
        "/ROOT/DATA/ARRAY",
        xmlDoc,
        null,
        XPathResult.ANY_TYPE,
        null
      );
      const blobs: { [index: number]: CMSArticle } = {};
      let result = nodes.iterateNext();
      while (result) {
        let id: number = null;
        let value: string = null;
        let title: string = null;
        result.childNodes.forEach((element) => {
          if (element.nodeName === "DOC_ID") {
            id = parseInt(element.textContent, 10);
          }
          if (element.nodeName === "TITLE") {
            title = element.textContent;
          }
          if (element.nodeName === "CONTENT") {
            value = element.textContent;
          }
        });
        blobs[id] = {
          title,
          content: value,
        };
        result = nodes.iterateNext();
      }
      return blobs;
    });
};
