import React, { FunctionComponent } from "react";
import { Label } from "@frontend/common/src/elements/Label/Label";
import { Input } from "./FormInput.styles";
import { FormControl, Error } from "../../elements/FormControl/FormControl";
import { RequiredFieldIndicator } from "../../elements/RequiredFieldIndicator/RequiredFieldIndicator";

type Props = {
  htmlFor?: string;
  label?: string;
  name: string;
  type: string;
  placeholder?: string;
  defaultValue?: string;
  inputRef?: any;
  error?: any;
  required?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: string) => void;
  readonly?: any;
  disabled?: any;
};
export const FormInput: FunctionComponent<Props> = ({
  htmlFor,
  label,
  name,
  type,
  placeholder,
  defaultValue,
  inputRef,
  error,
  required,
  onChange,
  disabled,
  readonly,
}) => {
  return (
    <FormControl>
      <Label htmlFor={htmlFor}>
        {label || null}
        {required ? <RequiredFieldIndicator /> : ""}
      </Label>
      {/* <InputWrapper> */}
      <Input
        readOnly={readonly}
        name={name}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        ref={inputRef}
        error={!!error}
        disabled={disabled}
        onChange={(e) => (onChange ? onChange(e.target.value) : () => {})}
      />
      {/* {suffix ? <Suffix>test</Suffix> : null} */}
      {/* </InputWrapper> */}

      {error ? <Error>{error}</Error> : null}
    </FormControl>
  );
};
