import React, { FunctionComponent } from "react";
import { useAppState } from "../../overmind";

type Props = {
  contentId: number;
};
export const CMSContent: FunctionComponent<Props> = ({ contentId }) => {
  const state = useAppState();

  let markup = "";
  if (!state.cms[contentId]) {
    markup = "Fant ikke innhold..";
  } else {
    markup = state.cms[contentId].content;
  }
  return <div dangerouslySetInnerHTML={{ __html: markup }} />;
};
