import React, { useEffect } from "react";
import { Page } from "@frontend/common/src/types/Page";
import { BlockButton } from "../../../components/Buttons/Buttons.styles";
import { createUrl } from "../../../app/pages";
import { useActions, useAppState, useEffects } from "../../../overmind";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { PreTreColors } from "../../../styles/constants";

export const ChooseProjectType = () => {
  const actions = useActions();
  const effects = useEffects();
  const state = useAppState();

  useEffect(() => {
    if (state.orders.autofill?.offerId !== undefined) {
      actions.orders.setQuotationOnDraftOrder({
        isQuotation: false,
        quickChoice: undefined,
      });
      actions.orders.setOrderConverted(false);
      effects.router.navigateTo(
        createUrl(Page.CREATE_ORDER, { slug: "kundeinfo" })
      );
    }
  }, [state.orders.autofill]);
  const onSubmit = (isQuotation: boolean, quickChoice?: string) => {
    actions.orders.setQuotationOnDraftOrder({ isQuotation, quickChoice });
    actions.orders.setOrderConverted(false);
    effects.router.navigateTo(
      createUrl(Page.CREATE_ORDER, { slug: "kundeinfo" })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "2em",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "35vw",
          justifyContent: "space-evenly",
        }}
      >
        <div>
          <div style={{ color: PreTreColors.beis, fontSize: "1.5rem" }}>
            Forespørsel
          </div>
          <CMSContent contentId={1454} />
          <BlockButton onClick={() => onSubmit(true, undefined)}>
            Lag ny forespørsel
          </BlockButton>
        </div>
        <div style={{ marginTop: "5em" }}>
          <div style={{ color: PreTreColors.beis, fontSize: "1.5rem" }}>
            Bestilling
          </div>
          <CMSContent contentId={1455} />
          <BlockButton onClick={() => onSubmit(false, undefined)}>
            Lag ny bestilling
          </BlockButton>
        </div>

        <div style={{ marginTop: "5em" }}>
          <div style={{ color: PreTreColors.beis, fontSize: "1.5rem" }}>
            Hurtigvalg
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <BlockButton
              onClick={() => onSubmit(true, "extendedRoof")}
              style={{ width: "10em", marginTop: "1em" }}
            >
              Takstolkalkulator
            </BlockButton>
            <BlockButton
              onClick={() => onSubmit(true, "technical")}
              style={{ width: "10em", marginTop: "1em" }}
            >
              Tekniske tjenester
            </BlockButton>
          </div>
        </div>
      </div>
    </div>
  );
};
