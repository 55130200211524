import styled from "styled-components";

export const Callout = styled("label")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border: 1px solid yellow;
  background: lightyellow;
  margin-top: 1rem;
  a {
    display: inline-block;
  }
`;
