export const approvedRoofTypes = ["1", "10", "11", "2", "12", "4", "13", "6"];
export const configParams = [
  {
    roofType: "1",
    minProtrusion: 0,
    maxProtrusion: 800,
    minAngle: 30,
    maxAngle: 45,
    minRangeDistance: 3000,
    maxRangeDistance: 9000,
  },
  {
    roofType: "10",
    minProtrusion: 0,
    maxProtrusion: 800,
    minAngle: 30,
    maxAngle: 45,
    minRangeDistance: 3000,
    maxRangeDistance: 9000,
  },
  {
    roofType: "11",
    minProtrusion: 0,
    maxProtrusion: 800,
    minAngle: 30,
    maxAngle: 45,
    minRangeDistance: 3000,
    maxRangeDistance: 9000,
  },
  {
    roofType: "2",
    minProtrusion: 0,
    maxProtrusion: 650,
    minAngle: 15,
    maxAngle: 40,
    minRangeDistance: 2000,
    maxRangeDistance: 9000,
  },
  {
    roofType: "12",
    minProtrusion: 0,
    maxProtrusion: 650,
    minAngle: 15,
    maxAngle: 40,
    minRangeDistance: 2000,
    maxRangeDistance: 9000,
  },
  {
    roofType: "4",
    minProtrusion: 0,
    maxProtrusion: 650,
    minAngle: 15,
    maxAngle: 40,
    minRangeDistance: 2000,
    maxRangeDistance: 8000,
  },
  {
    roofType: "13",
    minProtrusion: 0,
    maxProtrusion: 650,
    minAngle: 15,
    maxAngle: 40,
    minRangeDistance: 2000,
    maxRangeDistance: 8000,
  },
  {
    roofType: "6",
    minProtrusion: 0,
    maxProtrusion: 650,
    minAngle: 0,
    maxAngle: 360,
    minRangeDistance: 2000,
    maxRangeDistance: 8000,
  },
];

export const ww = [
  {
    label: "36mm",
    value: 36,
  },
  {
    label: "48mm",
    value: 48,
  },
];
