import React, { FunctionComponent } from "react";

import { EmployeeMessageReferenceType } from "@frontend/common/src/types/EmployeeMessage";
import { Employee } from "@frontend/common/src/types/Employee";
import { Link } from "@frontend/common/src/elements/Link/Link";
import { useActions } from "../../overmind";

type Props = {
  employee: Employee;
  referenceId: string;
  referenceType: EmployeeMessageReferenceType;
};
export const EmployeeLink: FunctionComponent<Props> = ({
  employee: employe,
  referenceId,
  referenceType,
}) => {
  const actions = useActions();

  if (!employe) {
    return <span>Ikke satt</span>;
  }
  if (employe.hide) {
    return <span>{employe.name}</span>;
  }
  return (
    <Link
      onClick={() =>
        actions.onContactPerson({
          employeeId: employe.id,
          referenceId,
          referenceType,
        })
      }
    >{`${employe.name}`}</Link>
  );
};
