import React, { FunctionComponent } from "react";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import { useAuth0 } from "@auth0/auth0-react";
import { CMSContent } from "../../components/CMSContent/CMSContent";
import { PreTreColors, LayoutConstants } from "../../styles/constants";
import { Header } from "../../elements/Header/Header";
import { BlockButton } from "../../components/Buttons/Buttons.styles";

import {
  Card,
  CardHeader,
} from "../../components/ShortcutCard/ShortcutCard.styles";
import {
  ShortcutCardLink,
  ShortcutArrowIcon,
} from "../../components/ShortcutCard/ShortcutCard";
import { VBox } from "../../elements/VBox/VBox";
import {
  Page,
  Shader,
  BannerImage,
  Container,
  ItemContainer,
  VerticalCenterBox,
  SubText,
  BtnWrapper,
} from "./HomePage.styles";
import { useAppState } from "../../overmind";

// TODO Remove this if dont wanna use
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

export const HomePage: FunctionComponent = () => {
  const state = useAppState();

  // TODO Remove this if dont wanna use
  const { width } = useWindowDimensions();

  const isGuest = state.auth.user === null;

  const { loginWithRedirect } = useAuth0();
  return (
    <Page>
      <BannerImage>
        <Shader>
          <Container>
            {/* Left Side */}
            <ItemContainer
              style={{
                textAlign:
                  width >= LayoutConstants.sidebarBreak ? "left" : "center",
              }}
            >
              <VerticalCenterBox>
                <SubText>
                  <CMSContent
                    contentId={
                      isGuest
                        ? CMSArticles.WELCOME_GUEST_1
                        : CMSArticles.WELCOME_LOGGED_IN_1
                    }
                  />
                </SubText>
                <BtnWrapper
                  style={{
                    maxWidth:
                      width >= LayoutConstants.sidebarBreak ? "200px" : "100%",
                  }}
                >
                  {!isGuest ? null : (
                    <BlockButton onClick={() => loginWithRedirect()}>
                      Logg inn
                    </BlockButton>
                  )}
                </BtnWrapper>
              </VerticalCenterBox>
            </ItemContainer>
            {/* Right Side */}
            {width >= LayoutConstants.sidebarBreak ? (
              <ItemContainer>
                <VerticalCenterBox>
                  <Card>
                    <CardHeader>Nyttige snarveier</CardHeader>
                    {!isGuest ? (
                      <VBox>
                        <ShortcutCardLink href="/mine-prosjekt">
                          <ShortcutArrowIcon />
                          Mine prosjekt
                        </ShortcutCardLink>
                        <ShortcutCardLink href="/nytt-prosjekt">
                          <ShortcutArrowIcon />
                          Nytt prosjekt
                        </ShortcutCardLink>

                        {/* <ShortcutCardLink>
                        <ShortcutArrowIcon />
                        Avvik
                      </ShortcutCardLink> */}
                        <ShortcutCardLink
                          href="https://www.pretre.no/kontakt/administrasjon/"
                          target="_blank"
                        >
                          <ShortcutArrowIcon />
                          Kontaktinformasjon Pretre
                        </ShortcutCardLink>
                      </VBox>
                    ) : (
                      <VBox>
                        <ShortcutCardLink
                          href="https://kjorelister.pretre.no/"
                          target="_blank"
                        >
                          <ShortcutArrowIcon />
                          Pålogging Kjørelister
                        </ShortcutCardLink>
                        <ShortcutCardLink href="/glemt-passord">
                          <ShortcutArrowIcon />
                          Er bruker, glemt passord
                        </ShortcutCardLink>
                        <ShortcutCardLink
                          href="https://www.pretre.no/kontakt/administrasjon/"
                          target="_blank"
                        >
                          <ShortcutArrowIcon />
                          Kontaktinformasjon Pretre
                        </ShortcutCardLink>
                      </VBox>
                    )}
                  </Card>
                </VerticalCenterBox>
              </ItemContainer>
            ) : null}
          </Container>
        </Shader>
      </BannerImage>
      {width < LayoutConstants.sidebarBreak ? (
        <div
          style={{
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: -25,
            marginBottom: -70,
          }}
        >
          <ItemContainer style={{ justifyContent: "center" }}>
            <VerticalCenterBox>
              <Card style={{ padding: "20px 25px" }}>
                <CardHeader style={{ fontSize: 26 }}>
                  Nyttige snarveier
                </CardHeader>

                {!isGuest ? (
                  <VBox>
                    <ShortcutCardLink
                      style={{ fontSize: 16 }}
                      href="/mine-prosjekt"
                    >
                      <ShortcutArrowIcon />
                      Mine prosjekt
                    </ShortcutCardLink>
                    <ShortcutCardLink
                      style={{ fontSize: 16 }}
                      href="/nytt-prosjekt"
                    >
                      <ShortcutArrowIcon />
                      Nytt prosjekt
                    </ShortcutCardLink>
                    {/* <ShortcutCardLink>
                        <ShortcutArrowIcon />
                        Avvik
                      </ShortcutCardLink> */}
                    <ShortcutCardLink
                      style={{ fontSize: 16 }}
                      href="https://www.pretre.no/kontakt/administrasjon/"
                      target="_blank"
                    >
                      <ShortcutArrowIcon />
                      Kontaktinformasjon Pretre
                    </ShortcutCardLink>
                  </VBox>
                ) : (
                  <VBox>
                    <ShortcutCardLink
                      href="https://kjorelister.pretre.no/"
                      target="_blank"
                    >
                      <ShortcutArrowIcon />
                      Pålogging Kjørelister
                    </ShortcutCardLink>
                    <ShortcutCardLink href="/glemt-passord">
                      <ShortcutArrowIcon />
                      Er bruker, glemt passord
                    </ShortcutCardLink>
                    <ShortcutCardLink
                      href="https://www.pretre.no/kontakt/administrasjon/"
                      target="_blank"
                    >
                      <ShortcutArrowIcon />
                      Kontaktinformasjon Pretre
                    </ShortcutCardLink>
                  </VBox>
                )}
              </Card>
            </VerticalCenterBox>
          </ItemContainer>
        </div>
      ) : null}
      <div
        style={{
          width: "80%",
          margin: "50px auto",
          textAlign: "center",
        }}
      >
        <div
          style={{
            maxWidth: 800,
            textAlign:
              width >= LayoutConstants.sidebarBreak ? "left" : "center",
          }}
        >
          <Header>
            Velkommen til <br />
            Pretre AS kundeportal
          </Header>
          <div
            style={{
              color: PreTreColors.beis,
              fontSize: 18,
              fontWeight: 400,
              marginTop: 16,
            }}
          >
            <CMSContent
              contentId={
                isGuest
                  ? CMSArticles.WELCOME_GUEST_2
                  : CMSArticles.WELCOME_LOGGED_IN_2
              }
            />
          </div>
        </div>
      </div>
    </Page>
  );
};
