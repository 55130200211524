import React, { FunctionComponent } from "react";
import { Label } from "@frontend/common/src/elements/Label/Label";
import { FormControl } from "@frontend/common/src/elements/FormControl/FormControl";
import { InputArea } from "./TextArea.styles";

type Props = {
  label?: string;
  name?: string;
  rows?: number;
  cols?: number;
  htmlFor?: string;
  inputRef?: any;
  as?: any;
} & React.HTMLProps<HTMLTextAreaElement>;

export const TextArea: FunctionComponent<Props> = ({
  label,
  name,
  rows,
  cols,
  htmlFor,
  inputRef,
  ...props
}) => {
  return (
    <FormControl>
      {label ? <Label htmlFor={htmlFor}>{label}</Label> : null}
      <InputArea
        name={name}
        rows={rows}
        cols={cols}
        ref={inputRef}
        {...props}
      />
    </FormControl>
  );
};
