import { BaseService } from "../BaseService";
import { endpoints } from "../endpoints";
import { IAuthService } from "./IAuthService";

export class AuthService extends BaseService implements IAuthService {
  async login(username: string, password: string): Promise<string> {
    const response = await this.makeRequest(endpoints.auth.login, {
      brukernavn: username,
      passord: password,
    }).then((r) => r.data);
    return response.token;
  }

  async checkToken(): Promise<boolean> {
    return this.makeRequest(endpoints.auth.checkToken).then((r) => r.data);
  }

  async forgotPassword(username: string): Promise<boolean> {
    return this.makeRequest(endpoints.auth.forgotPassword, {
      username,
    });
  }
}
