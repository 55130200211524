import React, { FunctionComponent } from "react";
import { Table, Column } from "@frontend/common/src/components/Table/Table";
import { CustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";
import { Link } from "../../elements/Link/Link";
import { useActions } from "../../overmind";
import { BlockButton } from "../Buttons/Buttons.styles";

type ColumnKey = keyof CustomerEmployee;

type Props = {
  users: CustomerEmployee[];
  columns?: ColumnKey[];
  // eslint-disable-next-line no-unused-vars
  onRowSelected?: (row: CustomerEmployee) => void;
};

const createColumns = (actions: any): Column<CustomerEmployee>[] => {
  const allColumns: Column<CustomerEmployee>[] = [
    {
      headerName: "Navn",
      field: "name",
      render: (row) => `${row.name}`,
    },
    {
      headerName: "Epost",
      field: "email",
    },
    {
      headerName: "Telefon",
      field: "phone",
      render: (row) => <Link href="tel:row.phone">{row.phone}</Link>,
    },
    {
      headerName: "Stilling",
      field: "position",
    },

    {
      headerName: "Aktiv",
      field: "active",
      render: (row) => (
        <BlockButton
          style={{ fontSize: ".75rem", padding: ".2rem" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            actions.changeUserActive({
              userId: row.id,
              activeState: !row.active,
            });
          }}
        >
          {row.active ? "Deaktiver" : "Aktiver"}
        </BlockButton>
      ),
    },
    {
      headerName: "Har Passord",
      field: "hasPassword",
      render: (row: CustomerEmployee) =>
        row.hasPassword ? (
          <svg height="1rem" viewBox="0 0 24 18">
            <path
              d="M0 9.91357L2.60829 6.52202L9.39163 12.2615L20.3488 0L23.4791 2.86985L9.65201 18L0 9.91357Z"
              fill="#CA0000"
            />
          </svg>
        ) : (
          ""
        ),
    },
    {
      headerName: "Handlinger",
      field: "hasPassword",
      render: (row) => (
        <div style={{ display: "flex" }}>
          <BlockButton
            style={{
              fontSize: ".75rem",
              padding: ".2rem",
              marginRight: "1rem",
            }}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();

              actions.onResetPassword(row.id);
            }}
          >
            Send passord
          </BlockButton>
        </div>
      ),
    },
  ];

  return allColumns;
};

export const UserList: FunctionComponent<Props> = ({
  users,
  columns,
  onRowSelected,
}) => {
  const actions = useActions();
  const allColumns = createColumns(actions);
  let columnsToUse = null;
  if (!columns) {
    columnsToUse = allColumns;
  } else {
    columnsToUse = columns.map((c) => allColumns.find((a) => a.field === c));
  }

  return (
    <Table
      keyField="id"
      data={users}
      columns={columnsToUse}
      availableColumns={allColumns}
      onRowSelected={onRowSelected}
    />
  );
};
