import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BlockButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { UserList } from "../../components/UserList/UserList";
import { Grid, Row, Col } from "../../elements/Grid/Grid";
import { useActions, useAppState } from "../../overmind";
import { CompanyForm } from "./components/CompanyForm/CompanyForm";
import { EditEmployeeModal } from "../../modals/EditEmployeeModal/EditEmployeeModal";
import { SalesPersonBox } from "../../components/SalesPersonBox/SalesPersonBox";

export const CompanyPage: FunctionComponent = () => {
  const state = useAppState();
  const actions = useActions();

  const { t } = useTranslation();

  return (
    <Page
      title={t("My profile - {{companyName}}", {
        companyName: state.customer.name,
      })}
    >
      <Grid>
        <Row>
          <Col size={1}>
            <CompanyForm />
            <SalesPersonBox employeeId={state.customer.salesPersonId} />
          </Col>
          <Col size={1}>
            <BlockButton
              onClick={() => actions.onEditEmployee()}
              style={{
                width: "100%",
                maxWidth: "11rem",
                padding: "0.5rem",
                fontSize: "1rem",
                marginBottom: "1rem",
              }}
            >
              Ny kontaktperson
            </BlockButton>
            <UserList
              users={state.customerEmployees.filter((e) => e.hasQuit === false)}
              onRowSelected={(customerEmployee) => {
                actions.onEditEmployee(customerEmployee.id);
              }}
            />
          </Col>
        </Row>
      </Grid>

      {state.modals.editEmployeeModal.isCurrent ? <EditEmployeeModal /> : null}
    </Page>
  );
};
