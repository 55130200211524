import {
  BIV,
  ExtendedPrecut,
  ExtendedPrecutBackend,
} from "../types/DraftOrder/ExtendedPrecut";

const stringCheck = (str: string): boolean => {
  return str !== null && str !== "";
};
const mapBiv = (
  stender?: string,
  toppsvill?: string,
  bunnsvill?: string
): BIV | null => {
  if (
    stringCheck(stender) ||
    stringCheck(toppsvill) ||
    stringCheck(bunnsvill)
  ) {
    return {
      dimStender: stender,
      dimToppsvill: toppsvill,
      dimBunnsvill: bunnsvill,
    };
  }
  return null;
};

export const mapToBackend = (plans: ExtendedPrecut[]) => {
  const backendList = plans.map((x) => {
    const backendObj: ExtendedPrecutBackend = {
      bruttoVegghoyde: x.bruttoVegghoyde,
      hoydeOverkantApning: x.hoydeOverkantApning,
      dimensjonToppsvill: x.dimensjonToppsvill,
      dimensjonStender: x.dimensjonStender,
      dimensjonBunnsvill: x.dimensjonBunnsvill,
      klaringTilKarm: x.klaringTilKarm,
      name: x.name,
      projectId: x.projectId,
      biv: [],
    };
    const biv1 = mapBiv(x.biv1, x.dimToppsvill1, x.dimBunnsvill1);
    if (biv1 !== null) {
      backendObj.biv.push(biv1);
    }
    const biv2 = mapBiv(x.biv2, x.dimToppsvill2, x.dimBunnsvill2);
    if (biv2 !== null) {
      backendObj.biv.push(biv2);
    }
    const biv3 = mapBiv(x.biv3, x.dimToppsvill3, x.dimBunnsvill3);
    if (biv3 !== null) {
      backendObj.biv.push(biv3);
    }
    return backendObj;
  });
  return JSON.stringify(backendList);
};
