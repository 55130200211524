import styled from "styled-components";
import { PreTreColors } from "../../styles/constants";

export const Link = styled("a")`
  color: ${PreTreColors.pretre};
  cursor: pointer;
  &:hover {
    color: ${PreTreColors.beis};
  }
`;

export const ActionLink = styled("a")`
  margin: 1rem;
  background: none;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  color: ${PreTreColors.beis};
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
  }
`;
