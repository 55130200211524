import React, { FunctionComponent } from "react";
import { Heading } from "./Header.styles";

type Props = {
  rest?: any;
  type?: string;
  theme?: string;
};
export const Header: FunctionComponent<Props> = ({
  children,
  type,
  theme,
  ...rest
}) => {
  return (
    <Heading theme={theme} type={type} {...rest}>
      {children}
    </Heading>
  );
};
