import React, { useState, FunctionComponent } from "react";
import Modal from "@frontend/common/src/components/Modal/Modal";
import { SelectionGrid } from "../../components/SelectionGrid/SelectionGrid";
import {
  BlockButton,
  LinkButton,
} from "../../components/Buttons/Buttons.styles";
import { useActions, useAppState } from "../../overmind";

interface Props {
  key: number;
  /** Header text */
  text: string;
  /** The URL for the image */
  src: string;
}

/**
 * Creates a modal which the user picks one of the items and returns the element to parent when user submits.
 * @param items     a pool of items
 * @constructor     -
 */
export const SelectionGridModal: FunctionComponent = () => {
  const [selectedItem, setSelectedItem] = useState<Props>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onItemClicked = (i: Props, e: any) => setSelectedItem(i);
  const { modals: modalsAction } = useActions();
  const { modals: modalsState } = useAppState();
  /**
   * Sends the selected item to parent.
   */
  const emitOnSubmit = () => {
    modalsAction.selectionGridModal.close(selectedItem);
  };

  const closeModal = () => {
    modalsAction.selectionGridModal.close(null);
  };

  const { items } = modalsState.selectionGridModal;

  return (
    <Modal
      isOpen={modalsState.selectionGridModal.isCurrent}
      onClose={() => closeModal()}
    >
      <SelectionGrid items={items} onItemClicked={onItemClicked} />

      <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr" }}>
        <span />
        <BlockButton onClick={() => emitOnSubmit()}>Velg</BlockButton>
        <LinkButton onClick={() => closeModal()}>Avbryt</LinkButton>
      </div>
    </Modal>
  );
};
