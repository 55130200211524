import React, { FunctionComponent, useRef } from "react";
import styled, { css } from "styled-components";
import { useOnClickOutside } from "webapp/src/hooks/useOnClickOutside";

export const ColumnPickerContainer = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
`;

const activeItemStyle = css`
  font-weight: bold;
`;

type ItemProps = {
  isActive?: boolean;
};

export const ColumnItem = styled("span")<ItemProps>`
  cursor: pointer;
  ${(props: ItemProps) => props.isActive && activeItemStyle}
`;
type Props = {
  availableColumns: string[];
  selectedColumns: string[];
  onColumnsChanged: (columns: string[]) => void;
  onClose: () => void;
};

export const ColumnPicker: FunctionComponent<Props> = ({
  availableColumns,
  selectedColumns,
  onColumnsChanged,
  onClose,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => {
    onClose();
  });
  return (
    <ColumnPickerContainer>
      <span style={{ fontWeight: "bold" }}>Velg kolonner:</span>
      {availableColumns.map((c) => {
        return (
          <ColumnItem
            isActive={!!selectedColumns.includes(c)}
            onClick={() => {
              let newColumns: string[] = [...selectedColumns];
              if (newColumns.includes(c)) {
                newColumns = newColumns.filter((col) => c !== col);
              } else {
                newColumns.push(c);
              }

              onColumnsChanged(newColumns);
            }}
          >
            {c}
          </ColumnItem>
        );
      })}
    </ColumnPickerContainer>
  );
};
