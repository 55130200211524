// eslint-disable-next-line no-shadow
export enum Page {
  LOGIN,
  HOME,
  RESET_PASSWORD,

  ORDER_LIST,
  VIEW_ORDER,
  VIEW_ORDER_PROJECT,
  CREATE_ORDER,

  TECHNICAL_SERVICES,

  CUSTOMERS,
  ADMIN,
  DOCUMENTATION,

  USER,
  COMPANY,

  ERROR,
  CRM = 18,
  EMULATE,
  AUTH,
  CUSTOMER_SELECTOR,
}
