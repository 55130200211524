import styled from "styled-components";
import { PreTreColors } from "../../styles/constants";

import { baseInputStyles } from "../../styles/baseInput";

type Props = {
  error?: boolean;
};
export const Input = styled("input")<Props>`
  ${baseInputStyles}
  display: inline-block;
  position: relative;
  width: 100%;
  ::placeholder {
    color: ${PreTreColors.beis};
    opacity: 0.7;
  }
`;
export const InputWrapper = styled("div")`
  width: 100%;
`;
export const Suffix = styled("span")`
  width: 100%;
  justify-content: end;
`;
