import { useEffect } from "react";

// https://usehooks.com/useOnClickOutside/
export function useOnClickOutside(ref: any, handler: any) {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements. Also checks for Material UI menu.
        if (
          !ref.current ||
          ref.current.contains(event.target) ||
          event.target.closest(".MuiDialog-root") !== null ||
          event.target.closest(".MuiPopover-root") !== null
        ) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, // ... but to optimize you can wrap handler in useCallback before ... // ... callback/cleanup to run every render. It's not a big deal ... // ... function on every render that will cause this effect ... // It's worth noting that because passed in handler is a new ... // Add ref and handler to effect dependencies
    // ... passing it into this hook.
    [ref, handler]
  );
}
