import React, { FunctionComponent, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextArea } from "@frontend/common/src/components/TextArea/TextArea";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as validators from "@frontend/common/src/validators";
import { Select } from "../../../components/Select/Select";

import { useAppState } from "../../../overmind";
import { OfferOptionType } from "../../../types/OfferOption";
import { FormProps } from "./RoofForm";
import { BlockButton } from "../../../components/Buttons/Buttons.styles";
import { DraftOrderSubProject } from "../../../types/DraftOrder/DraftOrderSubProject";
import { makeId } from "../../../helpers/GenerateId";

export const JoistsForm: FunctionComponent<FormProps> = ({
  setSubProjects,
  setActiveForm,
  subProjects,
  setSelectedSubProject,
  selectedSubProject,
  autoFill,
}) => {
  const { t } = useTranslation();
  const { offerOptions } = useAppState();
  const joists = yup.object().shape({
    joistsType: validators.string,
    submergeWetRoom: autoFill ? validators.string : validators.requiredField,
    millimetersImmersed: validators.positiveNumeric,
  });
  const onSubmit = (data: DraftOrderSubProject) => {
    if (data.projectId === undefined || data.projectId.length < 1) {
      // eslint-disable-next-line no-param-reassign
      data.projectId = makeId(8);
    }
    if (selectedSubProject !== undefined) {
      const newProjs = subProjects.map((x) => {
        if (x.projectId === data.projectId) {
          return {
            ...x,
            comment: data.comment,
            joistsType: data.joistsType ?? selectedSubProject.joistsType,
            submergeWetRoom:
              data.submergeWetRoom ?? selectedSubProject.submergeWetRoom,
          };
        }
        return x;
      });
      setSubProjects(newProjs);
    } else {
      setSubProjects([...subProjects, data]);
    }
    setActiveForm("");
    setSelectedSubProject(undefined);
  };
  const onCancel = () => {
    setActiveForm("");
    setSelectedSubProject(undefined);
  };

  const { register, handleSubmit, errors } = useForm<DraftOrderSubProject>({
    // @ts-ignore
    resolver: yupResolver(joists),
    defaultValues: selectedSubProject,
  });
  const joistsTypes = useMemo(
    () =>
      offerOptions
        .filter((o) => o.type === OfferOptionType.JOIST_TYPE)
        .map((o) => ({ label: o.description, value: o.id })),
    [offerOptions]
  );

  const submergeWetRooms = useMemo(
    () =>
      offerOptions
        .filter((o) => o.type === OfferOptionType.SUBMERGE_WETROOM)
        .map((o) => ({ label: o.description, value: o.id })),
    [offerOptions]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <div>
          <input ref={register} type="hidden" value="beamlayer" name="type" />
          <input ref={register} type="hidden" name="projectId" />

          <Select
            name="joistsType"
            label={t("Pick joist type")}
            options={joistsTypes}
            inputRef={register}
            disabled={
              autoFill &&
              selectedSubProject.joistsType !== null &&
              selectedSubProject.joistsType.length > 0
            }
            error={errors.joistsType?.message}
          />
          <Select
            name="submergeWetRoom"
            label={t("Immerse wetroom")}
            options={submergeWetRooms}
            inputRef={register}
            disabled={
              autoFill &&
              selectedSubProject.submergeWetRoom !== null &&
              selectedSubProject.submergeWetRoom.length > 0
            }
            error={errors.submergeWetRoom?.message}
            required={!autoFill}
          />

          <TextArea
            label={t("Kommentar")}
            name="comment"
            rows={3}
            inputRef={register}
            placeholder={t("Write other required info here")}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "30%",
          }}
        >
          <BlockButton style={{ marginBottom: "2em" }} type="submit">
            {selectedSubProject !== undefined ? "Oppdater" : "Legg til"}
          </BlockButton>
          <BlockButton onClick={() => onCancel()} type="button">
            Avbryt
          </BlockButton>
        </div>
      </div>
    </form>
  );
};
