import React, { FunctionComponent } from "react";
import Modal from "@frontend/common/src/components/Modal/Modal";
import { useAppState } from "../../overmind";
import { CMSContent } from "../../components/CMSContent/CMSContent";
import { PreTreColors } from "../../styles/constants";

interface ModalProps {
  cancelCalculation: () => void;
}

export const CalculatePriceModal: FunctionComponent<ModalProps> = ({
  cancelCalculation,
}: ModalProps) => {
  const { modals } = useAppState();

  if (!modals.calculatePriceModal.isCurrent) {
    return null;
  }

  return (
    <Modal
      isOpen={modals.calculatePriceModal.isCurrent}
      onClose={() => cancelCalculation()}
    >
      <div
        style={{
          backgroundColor: PreTreColors.pretre,
          color: "white",
          fontSize: "2.2rem",
          padding: "2rem",
          borderRadius: "10px",
          textAlign: "center",
          height: "3.5em",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span style={{ marginLeft: "auto", marginRight: "auto" }}>
          <CMSContent contentId={1465} />
        </span>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span
          style={{
            display: "flex",
            fontSize: "2.2rem",
            cursor: "pointer",
          }}
          onClick={() => cancelCalculation()}
        >
          X
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          fontSize: "2rem",
          height: "10em",
        }}
      >
        <CMSContent contentId={1466} />
      </div>
    </Modal>
  );
};
