import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { PreTreColors } from "../../styles/constants";

const List = styled("div")`
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  margin-left: 2em;
  margin-right: 2em;
  justify-content: center;
  text-align: center;
`;

type StepProps = {
  align: any;
};

const Step = styled("a")<StepProps>`
  color: #fff;

  margin: 0.25rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 4px;
  display: inline-flex;
  background-color: ${PreTreColors.pretre};
  &[disabled] {
    background-color: #dbdbdb;
    border-color: #cccccc;
    color: #7a7a7a;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const currentLinkStyle = css`
  background-color: ${PreTreColors.pretre};
  color: white;
`;

type LinkProps = {
  current?: any;
} & React.HTMLProps<HTMLAnchorElement>;
const Link = styled("a")<LinkProps>`
  color: #363636;
  border: 1px solid ${PreTreColors.grillaBrite};
  min-width: auto;
  margin: 0.25rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 4px;
  display: inline-flex;

  ${(props: LinkProps) => props.current && currentLinkStyle}
`;
const Ellipsis = styled("a")`
  font-size: 1em;
  justify-content: center;
  margin: 0.25rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: center;

  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
`;

type PaginationProps = {
  align: any;
};

const Pagination = styled("div")<PaginationProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
`;

function renderLink(pageNumber: number, key: string, link: string) {
  return (
    <Link key={key} href={`${link}${pageNumber}`}>
      {pageNumber}
    </Link>
  );
}

function renderPrev(currentPage: number, pageCount: number, link: string) {
  return [
    <Step
      key="prev"
      {...(currentPage === 1 ? { disabled: true } : {})}
      align="previous"
      href={currentPage === 1 ? "#" : `${link}${currentPage - 1}`}
    >
      Forrige
    </Step>,
  ];
}

function renderNext(currentPage: number, pageCount: number, link: string) {
  return [
    <Step
      key="next"
      {...(currentPage >= pageCount ? { disabled: true } : {})}
      align="next"
      href={currentPage >= pageCount ? "#" : `${link}${currentPage + 1}`}
    >
      Neste
    </Step>,
  ];
}

function renderLinks(currentPage: number, pageCount: number, link: string) {
  const items = [];
  if (currentPage !== 1 && pageCount > 1) {
    items.push(renderLink(1, "first", link));
    if (currentPage !== 2) {
      items.push(<Ellipsis key="space-before">…</Ellipsis>);
      items.push(renderLink(currentPage - 1, "prev-current", link));
    }
  }

  items.push(
    <Link key="current" current>
      {currentPage}
    </Link>
  );

  if (currentPage !== pageCount && pageCount > currentPage) {
    items.push(renderLink(currentPage + 1, "next-current", link));
    if (currentPage !== pageCount - 1) {
      items.push(<Ellipsis key="space-after" />);
      items.push(renderLink(pageCount, "last", link));
    }
  }
  return items;
}

type Props = {
  currentPage: number;
  pageCount: number;
  link: string;
  mode?: "tiny";
};

export const Pager: FunctionComponent<Props> = ({
  currentPage,
  pageCount,
  link,
  mode,
}) => {
  return (
    <Pagination align="centered">
      {renderPrev(currentPage, pageCount, link)}
      {(!mode || mode !== "tiny") && (
        <List>{renderLinks(currentPage, pageCount, link)}</List>
      )}
      {renderNext(currentPage, pageCount, link)}
    </Pagination>
  );
};
