import { isValid } from "date-fns";
import { DraftOrderRoof } from "../types/DraftOrder/DraftOrderRoof";
import { DraftOrderJoists } from "../types/DraftOrder/DraftOrderJoists";
import { DraftOrderPrecut } from "../types/DraftOrder/DraftOrderPrecut";
import { DraftOrder } from "../types/DraftOrder/DraftOrder";

export const mapNotatB2b = (
  noteB2b: any
): {
  roof: DraftOrderRoof;
  joists: DraftOrderJoists;
  precut: DraftOrderPrecut;
} => {
  if (!noteB2b) return null;
  let json;
  if (typeof noteB2b === "string") {
    const fixed = noteB2b.replace(",\n}", "\n}");
    json = JSON.parse(fixed);
  } else if (typeof noteB2b === "object") {
    json = noteB2b;
  } else {
    // invalid variable
    return null;
  }
  const model: {
    roof: DraftOrderRoof;
    joists: DraftOrderJoists;
    precut: DraftOrderPrecut;
  } = { roof: null, joists: null, precut: null };

  model.roof = {
    hasRoof: json.HarTak,
    roofType: json.TakType,
    roofingType: json.Taktekking,
    angle: json.Takvinkel,
    rangeDistance: json.Spennvidde,
    protrusion: json.Utstikk,
    comment: json.KommentarTak,
  } as DraftOrderRoof;

  model.joists = {
    hasJoists: json.HarBjelkelag,
    joistsChoice: json.BjelkelagType,
    joistsType: json.BjelkelagValg,
    submergeWetRoom: json.NedsenkVaatrom,
    comment: json.KommentarBjelkelag,
  } as DraftOrderJoists;

  model.precut = {
    hasPrecut: json.HarPrecut,
    dimensions: json.PrecutDimensjon,
    comment: json.KommentarPrecut,
  } as DraftOrderPrecut;

  return model;
};
export const mapDraftOrder = (data: any): DraftOrder => {
  if (!data) return null;

  return data as unknown as DraftOrder;
};

export const mapDraftOrderToBackendModel: any = (
  offerId: number,
  customerId: number,
  order: DraftOrder,
  loggedInEmail: string,
  projectId: string
) => {
  if (!order) return null;
  const model = {
    kundeId: customerId,
    customerId, // for endpoint
    tilbudNr: offerId,
    kundeKontaktId: order.customerContactId,
    merkes: order.marked,
    kommentar: "",
    levering: order.deliveryTerms,
    kundesBestillingsnr: order.customerOrderNumber,
    // @ts-ignore
    bestbekr: null,
    // @ts-ignore
    onsketLevering: null,
    // @ts-ignore
    leveringsadresse: null,
    byggeplass: {
      adresse: order.deliveryAddress,
      postnummer: order.zipCode?.toString(),
      lat: 0,
      lng: 0,
      gardsnr: order.farmNumber?.toString(),
      bruksnr: order.subFarmNumber?.toString(),
      hoydeOverHavet: order.mamsl,
      sammeSomByggeplass: order.deliveryAddressSameAsBuilding,
      tilkomstSemi: order.arrivalSemi === "1",
      kontpers2: order.contactPersonSite,
      tlflevkunde: order.contactPersonPhoneNr,
    },
    loggedInEmail,
    tilbudsvalg: order.subProjects,
    byggtype: order.buildingType,
    quotation: order.quotation,
    additionalInfo: order.additionalInfo,
    projectId,
  };

  model.onsketLevering =
    typeof order.preferredDeliveryDate === "string" &&
    // @ts-ignore
    order.preferredDeliveryDate.length > 0
      ? new Date(order.preferredDeliveryDate).toISOString()
      : isValid(order.preferredDeliveryDate)
      ? order.preferredDeliveryDate
      : null;

  if (order.expectedDrawingDate) {
    let date;
    switch (typeof order.expectedDrawingDate) {
      case "string":
        date = new Date(order.expectedDrawingDate);
        date = date.setHours(date.getHours() + 3);
        model.bestbekr = new Date(date).toISOString();
        break;
      default:
        date = order.expectedDrawingDate;
        date = date.setHours(date.getHours() + 3);
        model.bestbekr = new Date(date).toISOString();
        break;
    }
  }

  let leveringsadresse = {};

  if (!order.deliveryAddressSameAsBuilding) {
    leveringsadresse = {
      adresse: order.deliveryAddress2,
      postnummer: order.zipCode2?.toString(),
      sammeSomByggeplass: order.deliveryAddressSameAsBuilding,
      tilkomstSemi: order.arrivalSemi === "1",
    };
  } else {
    leveringsadresse = {
      adresse: order.deliveryAddress,
      postnummer: order.zipCode?.toString(),
      sammeSomByggeplass: order.deliveryAddressSameAsBuilding,
      tilkomstSemi: order.arrivalSemi === "1",
    };
  }

  model.leveringsadresse = leveringsadresse;
  return model;
};
