/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextArea } from "@frontend/common/src/components/TextArea/TextArea";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as validators from "@frontend/common/src/validators";
import axios, { CancelTokenSource } from "axios";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { Select } from "../../../components/Select/Select";

import { useActions, useAppState, useEffects } from "../../../overmind";
import { OfferOptionType } from "../../../types/OfferOption";
import { FormProps } from "./RoofForm";
import { BlockButton } from "../../../components/Buttons/Buttons.styles";
import { DraftOrderSubProject } from "../../../types/DraftOrder/DraftOrderSubProject";
import { makeId } from "../../../helpers/GenerateId";
import { CalculationPriceAndError } from "./CalculationPriceAndError";
import { CalcConditions } from "./CalcConditions";
import { CalculatePriceModal } from "../../../modals/CalculatePriceModal/CalculatePriceModal";
import {
  Address,
  Calculation,
  CalculationPayload,
} from "../../../types/DraftOrder/Calculation";
import Tooltip from "./Tooltip";
import { CMSContent } from "../../../components/CMSContent/CMSContent";

export const ExtendedBeamLayerForm: FunctionComponent<FormProps> = ({
  setSubProjects,
  setActiveForm,
  subProjects,
  setSelectedSubProject,
  selectedSubProject,
  autoFill,
}) => {
  const { t } = useTranslation();
  const { offerOptions, modals, orders, customer } = useAppState();
  const actions = useActions();
  const effects = useEffects();
  const cancelToken: React.MutableRefObject<CancelTokenSource | null> =
    useRef(null);
  const [calc, setCalc] = useState<Calculation | undefined>(undefined);
  const [priceDisabled, setPriceDisabled] = useState<boolean>(true);
  const [showPriceButton, setShowPriceButton] = useState<boolean>(false);
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const joists = yup.object().shape({
    joistsType: validators.string,
    // submergeWetRoom: autoFill ? validators.string : validators.requiredField,
    millimetersImmersed: validators.positiveNumeric,
  });

  const cancelCalculation = () => {
    if (cancelToken.current != null) {
      cancelToken.current.cancel();
    }
    // TODO remove
    setPriceDisabled(false);
    setShowPriceButton(false);
    actions.modals.calculatePriceModal.close();
  };
  const onSubmit = (data: DraftOrderSubProject) => {
    if (data.projectId === undefined || data.projectId.length < 1) {
      // eslint-disable-next-line no-param-reassign
      data.projectId = makeId(8);
    }
    if (calc !== undefined) {
      // eslint-disable-next-line no-param-reassign
      data.conditions = calc.conditions;
      // eslint-disable-next-line no-param-reassign
      data.preconditions = calc.preconditions;
      // eslint-disable-next-line no-param-reassign
      data.priceConditions = calc.priceConditions;
    }
    // eslint-disable-next-line no-param-reassign
    data.submergeWetRoom = data.totalAmountImmersed > 0 ? "Ja" : "Nei";
    if (selectedSubProject !== undefined) {
      const newProjs = subProjects.map((x) => {
        if (x.projectId === data.projectId) {
          return {
            ...x,
            comment: data.comment,
            joistsType: data.joistsType ?? selectedSubProject.joistsType,
            submergeWetRoom:
              data.submergeWetRoom ?? selectedSubProject.submergeWetRoom,
            totalAmountJoists:
              data.totalAmountJoists ?? selectedSubProject.totalAmountJoists,
            totalAmountImmersed:
              data.totalAmountImmersed ??
              selectedSubProject.totalAmountImmersed,
            steelBeamLength:
              data.steelBeamLength ?? selectedSubProject.steelBeamLength,
          };
        }
        return x;
      });
      setSubProjects(newProjs);
    } else {
      setSubProjects([...subProjects, data]);
    }
    setActiveForm("");
    setSelectedSubProject(undefined);
  };
  const onCancel = () => {
    setActiveForm("");
    setSelectedSubProject(undefined);
  };

  const { register, handleSubmit, errors, getValues, setValue } =
    useForm<DraftOrderSubProject>({
      // @ts-ignore
      resolver: yupResolver(joists),
      defaultValues: selectedSubProject,
    });
  const joistsTypes = useMemo(
    () =>
      offerOptions
        .filter((o) => o.type === OfferOptionType.JOIST_TYPE)
        .map((o) => ({ label: o.description, value: o.id })),
    [offerOptions]
  );

  useEffect(() => {
    if (selectedSubProject) {
      if (selectedSubProject.price) {
        setCalc({
          price: selectedSubProject.price,
          uid: selectedSubProject.calculationId,
          conditions: selectedSubProject.conditions ?? [],
          preconditions: selectedSubProject.preconditions ?? [],
          specifications: [],
          freight: selectedSubProject.freight,
          priceConditions: selectedSubProject.priceConditions ?? [],
        });
      }
    }
  }, [selectedSubProject]);
  const calclulatePrice = () => {
    actions.modals.calculatePriceModal.open();
    cancelToken.current = axios.CancelToken.source();
    const formValues = getValues([
      "projectId",
      "numberOfTrusses",
      "angle",
      "workingWidth",
      "roofType",
      "rangeDistance",
      "roofingType",
      "protrusion",
      "roomHeight",
      "roomWidth",
    ]);
    const uid =
      formValues.projectId !== null && formValues.projectId.length > 0
        ? formValues.projectId
        : makeId(4);
    const { currentNewOrder } = orders;
    const address: Address = {
      street: currentNewOrder.deliveryAddress,
      height: Number(currentNewOrder.mamsl),
      city: currentNewOrder.city,
      zipCode: currentNewOrder.zipCode,
      gardsnr:
        currentNewOrder.farmNumber !== null
          ? currentNewOrder.farmNumber.toString()
          : null,
      bruksnr:
        currentNewOrder.subFarmNumber !== null
          ? currentNewOrder.subFarmNumber.toString()
          : null,
    };
    const body: CalculationPayload = {
      uid,
      projectType: "Takstol",
      buildingType:
        currentNewOrder.buildingType.length > 0
          ? currentNewOrder.buildingType
          : null,
      CustomerId: customer.id,
      deliveryterms: currentNewOrder.deliveryTerms,

      address,
    };
    effects.api.order
      .calculateRoof(body, cancelToken.current)
      .then((data) => {
        setCalc(data);
        setValue("price", data.price);
        setValue("calculationId", data.uid);
        setValue("freight", data.freight);
        cancelToken.current = null;
        actions.modals.calculatePriceModal.close();
      })
      .catch(() => {
        effects.toast("Noe gikk galt, vennligst prøv igjen senere", 5000);
        cancelToken.current = null;
        actions.modals.calculatePriceModal.close();
      });
  };
  const toolTipContent = () => {
    return <div />;
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <div>
          <input
            ref={register}
            type="hidden"
            value="extendedBeamlayer"
            name="type"
          />
          <input ref={register} type="hidden" name="projectId" />

          <Select
            name="joistsType"
            label={t("Pick joist type")}
            options={joistsTypes}
            inputRef={register}
            disabled={
              autoFill &&
              selectedSubProject.joistsType !== null &&
              selectedSubProject.joistsType.length > 0
            }
            error={errors.joistsType?.message}
          />
          <FormInput
            label="Total mengde bjelkelag"
            type="number"
            name="totalAmountJoists"
            suffix="m2"
            inputRef={register}
            readonly={calc !== undefined}
            disabled={autoFill}
            error={errors.angle?.message}
          />
          <FormInput
            label="Andel nedsenket bjelkelag"
            type="number"
            name="totalAmountImmersed"
            suffix="m2"
            inputRef={register}
            readonly={calc !== undefined}
            disabled={autoFill}
            error={errors.angle?.message}
          />
          <FormInput
            label="Lengde innfelt stålbjelke"
            type="number"
            name="steelBeamLength"
            suffix="m"
            inputRef={register}
            readonly={calc !== undefined}
            disabled={autoFill}
            error={errors.angle?.message}
          />

          <TextArea
            label={t("Kommentar")}
            name="comment"
            rows={3}
            inputRef={register}
            placeholder={t("Write other required info here")}
          />
          {/* calc === undefined && showPriceButton ? (
            <div
              style={{
                display: "inline",
                position: "relative",
              }}
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
            >
              <Tooltip show={showToolTip}>{toolTipContent()}</Tooltip>
              <BlockButton
                style={{
                  marginRight: "2em",
                  backgroundColor: priceDisabled ? "gray" : "green",
                  width: "12em",
                }}
                onClick={() => calclulatePrice()}
                type="button"
                disabled={priceDisabled}
              >
                Beregn pris
              </BlockButton>
            </div>
          ) : calc ? (
            <BlockButton
              style={{
                marginRight: "2em",
                backgroundColor: "green",
                width: "12em",
              }}
              onClick={() => setCalc(undefined)}
              type="button"
            >
              Rediger
            </BlockButton>
          ) : null */}
          <CMSContent contentId={1475} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "576px" }}>
            <CalculationPriceAndError calc={calc} />
          </div>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "flex-end",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: "auto",
            }}
          >
            <BlockButton
              style={{ marginRight: "2em", width: "12em", fontSize: "1rem" }}
              type="submit"
            >
              {selectedSubProject !== undefined ? "Oppdater" : "Legg til"}
            </BlockButton>
            <BlockButton
              style={{ width: "12em", fontSize: "1rem" }}
              onClick={() => onCancel()}
              type="button"
            >
              Avbryt
            </BlockButton>
          </div>
          <CalcConditions calc={calc} />
        </div>
      </div>
      {modals.calculatePriceModal.isCurrent ? (
        <CalculatePriceModal cancelCalculation={cancelCalculation} />
      ) : null}
    </form>
  );
};
