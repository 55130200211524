import styled from "styled-components";

type Props = {
  row?: boolean;
  align?: boolean;
};
export const FormControl = styled("div")<Props>`
  display: flex;
  flex-direction: ${(props: Props) => (props.row ? "row" : "column")};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: ${(props: Props) => (props.align ? "center" : "")};
`;

export const Error = styled("p")`
  color: red;
  margin-top: 0.5rem;
  &:first-letter {
    text-transform: uppercase;
  }
`;
