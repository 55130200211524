import React, { FunctionComponent } from "react";

type Props = {
  embedId: string | number;
  embedHash: string;
};
export const SketchFabEmbed: FunctionComponent<Props> = ({
  embedId,
  embedHash,
}) => {
  return (
    <div className="sketchfab-embed-wrapper">
      <iframe
        title="A 3D model"
        width="421"
        height="258"
        src={`https://sketchfab.com/models/${embedHash}/embed?autostart=0&amp;ui_controls=0&amp;ui_infos=0&amp;ui_inspector=0&amp;ui_stop=0&amp;ui_watermark=1&amp;ui_watermark_link=1`}
        frameBorder="0"
        allow="autoplay; fullscreen; vr"
        allowFullScreen
      />
      <p
        style={{
          fontSize: "13px",
          fontWeight: "normal",
          margin: "5px",
          color: "#4A4A4A",
        }}
      >
        <a
          href={`https://sketchfab.com/3d-models/${embedId}-${embedHash}?utm_medium=embed&utm_source=website&utm_campaign=share-popup`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "bold", color: "#1CAAD9" }}
        >
          {embedId}
        </a>
        by{" "}
        <a
          href="https://sketchfab.com/pretre?utm_medium=embed&utm_source=website&utm_campaign=share-popup"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "bold", color: "#1CAAD9" }}
        >
          Pretre AS
        </a>
        on{" "}
        <a
          href="https://sketchfab.com?utm_medium=embed&utm_source=website&utm_campaign=share-popup"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "bold", color: "#1CAAD9" }}
        >
          Sketchfab
        </a>
      </p>
    </div>
  );
};
