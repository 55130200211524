import React, { FunctionComponent } from "react";

import { Link } from "../../elements/Link/Link";

type Props = {
  number: string;
};
export const PhoneLink: FunctionComponent<Props> = ({ number }) => {
  if (!number) {
    return <span>N/A</span>;
  }
  // only attempt to format for valid norwegian / 8 digit numbers. Should pull in some lib here if we need more
  if (number.trim().length === 8) {
    const formatted = `${number.substring(0, 3)} ${number.substring(
      3,
      5
    )} ${number.substring(5, 8)}`;
    return <Link href={`tel:${number}`}>{`${formatted}`}</Link>;
  }
  return <Link href={`tel:${number}`}>{`${number}`}</Link>;
};
