import styled from "styled-components";

import { PreTreColors } from "../../styles/constants";

export const TableHead = styled("thead")`
  background-color: ${PreTreColors.weakBrite};
  text-align: left;
`;

export const TableContainer = styled("table")`
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
`;

type TableRowProps = {
  alternate?: any;
  hasOpenHandler?: any;
  notVisible?: boolean;
  child?: boolean;
};

type TableCellProps = {
  child?: boolean;
};

export const TableRow = styled("tr")<TableRowProps>`
  display: ${(props: TableRowProps) =>
    props.notVisible ? "none" : "table-row"};
  background-color: ${(props: TableRowProps) =>
    props.alternate === true
      ? "#F4F4F5"
      : props.child === true
      ? "#eaeaec"
      : ""};
  cursor: ${(props: TableRowProps) =>
    props.hasOpenHandler === true ? "pointer" : "arrow"};
`;

export const TableCell = styled("td")<TableCellProps>`
  padding: 0.5rem 0.5rem 0.5rem
    ${(props: TableCellProps) => (props.child ? "4rem" : "0.5rem")};
`;

export const TableHeading = styled("td")`
  padding: 0.5rem;
  font-weight: bold;
  cursor: pointer;
`;
export const TableHeadingText = styled("span")`
  vertical-align: middle;
`;
