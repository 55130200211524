import React, { FunctionComponent, useState } from "react";
import { Column, Table } from "@frontend/common/src/components/Table/Table";
import { ExpandIcon } from "@frontend/common/src/elements/ExpandIcon/ExpandIcon";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/types/Page";
import { EmployeeMessageReferenceType } from "@frontend/common/src/types/EmployeeMessage";
import { formatDate } from "@frontend/common/src/helpers/date";
import { Order } from "../../types/Order";
import { Link } from "../../elements/Link/Link";

import { createUrl } from "../../app/pages";
import { RootState, useActions, useAppState } from "../../overmind";
import { formatMoney } from "../../helpers/money";
import { getDeliveryDateText } from "../../helpers/order";
import { EmployeeLink } from "../EmployeeLink/EmployeeLink";

type ColumnKey = keyof Order;

type Props = {
  orders: Order[];
  columns?: ColumnKey[];
  noResultsText?: string;
};

const createColumns = (
  actions: any,
  state: RootState,
  t: any
): Column<Order>[] => {
  const allColumns: Column<Order>[] = [
    {
      headerName: t("Project no."),
      field: "id",
      render: (row, openRows) => {
        const page =
          row.projectId === null ? Page.VIEW_ORDER_PROJECT : Page.VIEW_ORDER;
        if (row.isProjectMaster) {
          return (
            <div>
              <ExpandIcon expanded={openRows.includes(row.id)} />
              <Link href={createUrl(page, { id: row.id })}>{row.id}</Link>
            </div>
          );
        }
        return <Link href={createUrl(page, { id: row.id })}>{row.id}</Link>;
      },
    },
    {
      headerName: t("Product"),
      field: "projectName",
    },
    {
      headerName: t("Status"),
      field: "status",
    },
    {
      headerName: t("Marked"),
      field: "marked",
    },
    {
      headerName: t("Order date"),
      field: "orderDate",
      render: (row) => formatDate(row.orderDate),
    },
    {
      headerName: "Ønsket levering",
      field: "delivery",
      render: (row) => getDeliveryDateText(row),
    },
    {
      headerName: t("Constructor"),
      field: "engineerId",
      render: (row) => {
        const employee = state.pretreEmplyees.find(
          (e) => e.id === row.engineerId
        );
        return (
          <EmployeeLink
            employee={employee}
            referenceId={row.id}
            referenceType={EmployeeMessageReferenceType.ORDER}
          />
        );
      },
    },
    {
      headerName: t("Constructor"),
      field: "constructionName",
    },
    {
      headerName: t("Sales engineer"),
      field: "calculationName",
    },
    {
      headerName: t("Sales engineer"),
      field: "salesPersonId",
      render: (row) => {
        const employee = state.pretreEmplyees.find(
          (e) => e.id === row.salesPersonId
        );
        return (
          <EmployeeLink
            employee={employee}
            referenceId={row.id}
            referenceType={EmployeeMessageReferenceType.ORDER}
          />
        );
      },
    },
    {
      headerName: t("Customer Order no."),
      field: "customerOrderNumber",
    },
    {
      headerName: t("Order confirmation"),
      field: "id",
      render: () => <Link href="#">Klikk for OB</Link>,
    },
    {
      headerName: t("Price"),
      field: "sum",
      alignEnd: true,
      render: (row) => {
        let price = row.sum;
        if (row.isProjectMaster) {
          price = 0;
          row.subProjects.forEach((x) => {
            price += x.sum;
          });
        }
        return (
          <span style={{ textAlign: "right", float: "right" }}>
            {row.isOrderPriceCalculated && row.sum > 0 ? (
              <span style={{ display: "flex" }}>{formatMoney(price)}*</span>
            ) : (
              formatMoney(price)
            )}
          </span>
        );
      },
    },
  ];

  return allColumns;
};

export const OrderList: FunctionComponent<Props> = ({
  orders,
  columns,
  noResultsText,
}) => {
  const { t } = useTranslation();
  const actions = useActions();
  const state = useAppState();
  const allColumns = createColumns(actions, state, t);
  let columnsToUse = null;
  if (!columns) {
    columnsToUse = allColumns;
  } else {
    columnsToUse = columns.map((c) => allColumns.find((a) => a.field === c));
  }
  const [openRows, setOpenRows] = useState<string[]>([]);
  const onSelected = (selected: Order) => {
    if (openRows.includes(selected.id)) {
      const copy = openRows.filter((x) => x !== selected.id);
      setOpenRows(copy);
    } else {
      setOpenRows([...openRows, selected.id]);
    }
  };
  return (
    <Table
      noResultsText={noResultsText}
      keyField="id"
      data={orders}
      columns={columnsToUse}
      availableColumns={allColumns}
      onSortChanged={(field, direction) =>
        actions.orders.setSortOption({ field, direction })
      }
      currentSortOption={state.orders.currentOrderSortOption}
      openRows={openRows}
      onRowSelected={onSelected}
    />
  );
};
