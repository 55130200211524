export const sendMessageModal = {
  state: { userId: null as string },
  result: null as string,
};

export const editEmployeeModal = {
  state: { employeeId: null as string },
  result: null as void,
};

export const fileUploadModal = {
  state: {},
  result: null as FileList,
};
export const calculatePriceModal = {
  state: {},
  result: null as any,
};
export const selectionGridModal = {
  state: { items: null as any[] },
  result: null as any,
};

export const precutModal = {
  state: { projectId: null as string },
  result: null as void,
};
