import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import { CMSContent } from "../../components/CMSContent/CMSContent";

export const DocumentationPage: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("Documentation")}>
      <CMSContent contentId={CMSArticles.DOCUMENTATION} />
    </Page>
  );
};
