import React, { FunctionComponent, useState } from "react";
import { FileInput } from "@frontend/common/src/elements/FileInput/FileInput";
import { useTranslation } from "react-i18next";
import Modal from "@frontend/common/src/components/Modal/Modal";
import { useActions, useAppState } from "../../overmind";
import {
  BlockButton,
  LinkButton,
} from "../../components/Buttons/Buttons.styles";

export const FileUploadModal: FunctionComponent = () => {
  const { modals } = useAppState();

  const { modals: modalsActions } = useActions();

  const { t } = useTranslation();

  const [files, setFiles] = useState<File[]>([]);

  if (!modals.fileUploadModal.isCurrent) {
    return null;
  }
  const addFiles = (f: FileList) => {
    const file = files.concat(Array.from(f));
    setFiles(file);
  };
  const removeFile = (name: string) => {
    setFiles(files.filter((x) => x.name !== name));
  };
  return (
    <Modal
      isOpen={modals.fileUploadModal.isCurrent}
      onClose={() => modalsActions.fileUploadModal.close(null)}
    >
      <div
        style={{
          minHeight: "20vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <FileInput
          name="upload"
          label={t("Attach document")}
          multiple
          onChange={(f) => {
            addFiles(f);
          }}
        />
        {files.map((x) => {
          return (
            <div style={{ marginBottom: "0.5em" }}>
              <button
                type="button"
                onClick={() => removeFile(x.name)}
                style={{ marginRight: "2em" }}
              >
                Fjern fil
              </button>
              {x.name}
            </div>
          );
        })}
        <div style={{ display: "flex", justifySelf: "flex-end" }}>
          <BlockButton
            type="button"
            onClick={() => modalsActions.fileUploadModal.close(files)}
          >
            {t("Done")}
          </BlockButton>
          <LinkButton
            as="a"
            onClick={() => modalsActions.fileUploadModal.close(null)}
          >
            {t("Avbryt")}
          </LinkButton>
        </div>
      </div>
    </Modal>
  );
};
