import React, { FunctionComponent } from "react";
import { BlockButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { ExtendedPrecut } from "../../types/DraftOrder/ExtendedPrecut";
import { PreTreColors } from "../../styles/constants";

interface props {
  setPlans: (plans: ExtendedPrecut[]) => void;
  plans: ExtendedPrecut[];
  selectedPlan: ExtendedPrecut;
  setSelectedPlan: (plan: ExtendedPrecut) => void;
  setFormActive: (form: boolean) => void;
  autoFill: boolean;
}
export const PrecutPlans: FunctionComponent<props> = ({
  plans,
  setPlans,
  selectedPlan,
  setSelectedPlan,
  setFormActive,
  autoFill,
}) => {
  const deleteProject = (plan: ExtendedPrecut) => {
    if (autoFill) {
      const precuts = plans.filter((x) => x.name !== plan.name);
      setPlans([...precuts, { ...plan, deleted: true }]);
    } else {
      const projects = plans.filter((x) => x.name !== plan.name);
      setPlans(projects);
    }
  };
  /*
  const unDeleteProject = (subProject: DraftOrderSubProject) => {
    const map = subProjects.map((x) => {
      if (x.projectId === subProject.projectId) {
        return {
          ...x,
          deleted: false,
        };
      }
      return x;
    });
    setSubProjects(map);
  };

  */
  const onClickPlan = (plan: ExtendedPrecut) => {
    if (plan.deleted !== true) {
      setSelectedPlan(plan);
      setFormActive(true);
    }
  };
  return (
    <div
      style={{
        width: "20vw",
        backgroundColor: PreTreColors.borderlineGray,
        borderRadius: "10px",
        minHeight: "50vh",
        maxHeight: "50vh",
      }}
    >
      <div
        style={{
          backgroundColor: PreTreColors.pretre,
          color: "white",
          fontSize: "1.2rem",
          padding: "15px 0",
          borderRadius: "10px",
          textAlign: "center",
          height: "3.5em",
        }}
      >
        Etasjehøyder
      </div>
      <div style={{ overflowY: "auto", maxHeight: "45vh" }}>
        {plans.map((plan) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "1em",
              }}
              key={plan.name}
            >
              <div
                style={{
                  backgroundColor: selectedPlan === plan ? "gray" : "white",
                  display: "flex",
                  justifyContent: "space-between",
                  color: plan.deleted !== true ? "black" : "gray",
                  padding: "1em",
                  borderRadius: "10px",
                }}
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                  onClick={() => onClickPlan(plan)}
                >
                  <span
                    style={{ fontSize: "1.2rem", textDecoration: "underline" }}
                  >
                    {plan.name}
                  </span>
                </div>

                <BlockButton
                  type="button"
                  style={{ width: "20%", height: "2.5em" }}
                  onClick={() => deleteProject(plan)}
                >
                  Fjern
                </BlockButton>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
