import { Page } from "@frontend/common/src/elements/Page/Page";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BlockButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { useActions, useAppState } from "../../overmind";
import { Select } from "../../components/Select/Select";

const schema = yup.object().shape({
  customerId: yup.string().required(),
});

const CompanySelectorPage: FunctionComponent = () => {
  const state = useAppState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const actions = useActions();
  const onSubmit = (data: any) => {
    actions.auth.onMesterIdSelectCustomer(data.customerId);
  };

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  return (
    <Page title="Velg kunde">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          name="customerId"
          required
          label="Kunde"
          options={state.mesterIdCustomers.map((a) => {
            return { label: `${a.name}`, value: a.id };
          })}
          inputRef={register}
        />
        <BlockButton type="submit"> Velg kunde</BlockButton>
      </form>
    </Page>
  );
};

export default CompanySelectorPage;
