import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CMSArticles } from "@frontend//common/src/types/CMSArticle";
import { Page } from "@frontend/common/src/types/Page";
import { DateFormat, formatDate } from "@frontend/common/src/helpers/date";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import { Header } from "../../../elements/Header/Header";
import {
  BlockButton,
  LinkButton,
} from "../../../components/Buttons/Buttons.styles";
import { useActions, useAppState, useEffects } from "../../../overmind";
import { createUrl } from "../../../app/pages";
import {
  PageFlexConstraint,
  StyledList,
  StyledSection,
} from "./CreateOrderPage.styles";
import { OfferOptionType } from "../../../types/OfferOption";
import { DraftOrderSubProject } from "../../../types/DraftOrder/DraftOrderSubProject";

export const Summary: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const actions = useActions();
  const effects = useEffects();
  const {
    customer,
    offerOptions,
    orders: { currentNewOrder: order, autofill },
  } = useAppState();

  const roofTypes = offerOptions
    .filter((o) => o.type === OfferOptionType.ROOF)
    .map((o) => ({ label: o.description, value: o.id }));

  const joistsTypes = useMemo(
    () =>
      offerOptions
        .filter((o) => o.type === OfferOptionType.JOIST_TYPE)
        .map((o) => ({ label: o.description, value: o.id })),
    [offerOptions]
  );

  const dimensions = useMemo(
    () =>
      offerOptions
        .filter((o) => o.type === OfferOptionType.PRECUT_DIMENSION)
        .map((o) => ({ label: o.description, value: o.id })),
    [offerOptions]
  );

  const deterimineProductName = (subProj: DraftOrderSubProject) => {
    let returnVal = "";
    if (subProj.type === "technical") {
      returnVal = "Tekniske tjenester";
    } else if (subProj.type === "roof" || subProj.type === "extendedRoof") {
      const roof = roofTypes.find(
        (x) => x.value.toString() === subProj.roofType
      );
      returnVal = roof ? roof.label : "";
    } else if (
      subProj.type === "beamlayer" ||
      subProj.type === "extendedBeamlayer"
    ) {
      const joist = joistsTypes.find(
        (x) => x.value.toString() === subProj.joistsType
      );
      returnVal = joist ? joist.label : "";
    } else if (subProj.type === "precut" || subProj.type === "extendedPrecut") {
      const dim = dimensions.find(
        (x) => x.value.toString() === subProj.dimensions
      );
      returnVal = `Precut ${dim ? dim.label : ""}`;
    } else {
      returnVal = subProj.type;
    }
    return returnVal !== "" ? returnVal : subProj.type;
  };
  const requiredCheckboxItems = offerOptions.filter((o) => o.type === 10);

  const [checkboxes, setChecboxes] = useState<{ [index: string]: boolean }>({});
  const [submitting, setSumbitting] = useState(false);
  const onConfirm = async () => {
    const cb = Object.values(checkboxes);
    if (cb.length !== requiredCheckboxItems.length || cb.some((c) => !c)) {
      effects.toast("Se over sjekkliste", 5000);
      return;
    }
    setSumbitting(true);
    await actions.orders
      .commitDraftOrder({
        customerId: customer.id,
        order,
        offerId: autofill?.offerId,
      })
      .finally(() => {
        setSumbitting(false);
      });
  };
  const deliveryText = (deleted: boolean) => {
    if (deleted) {
      return ", fjernet";
    }
    if (
      order.preferredDeliveryDate !== null &&
      order.preferredDeliveryDate !== undefined
    ) {
      const formatted = formatDate(
        order.preferredDeliveryDate,
        DateFormat.REGULAR
      );
      if (formatted === null) return "";
      return `, levering ${formatted}`;
    }
    return "";
  };
  return (
    <PageFlexConstraint
      itemWidth="40vw"
      style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
    >
      <StyledSection>
        <Header type="secondary">6. {t("Oppsummering")}</Header>
        <article>
          <p>Oppsummering: </p>
          <ul style={{ paddingLeft: "20px" }}>
            {order.subProjects.map((x) => {
              return (
                <StyledList key={x.projectId}>
                  {deterimineProductName(x)} {deliveryText(x.deleted)}
                </StyledList>
              );
            })}
          </ul>
          {order.subProjects.length > 1 ? (
            <CMSContent contentId={CMSArticles.ORDER_SUMMARY_MULTIPLE} />
          ) : null}
        </article>

        <article>
          <b>Byggeaddresse:</b>
          <p>{order.deliveryAddress}</p>
          <p>{`${order.zipCode} ${order.city}`}</p>
        </article>

        {order.deliveryTerms === "Hentes fabrikk" ? (
          <article>
            <p>{order.deliveryTerms}</p>
          </article>
        ) : (
          <article>
            <b>Leveringsadresse:</b>
            <p>{order.deliveryAddress2 ?? order.deliveryAddress}</p>
            <p>
              {order.deliveryAddress2
                ? `${order.zipCode2} ${order.city2}`
                : `${order.zipCode} ${order.city}`}
            </p>
          </article>
        )}

        <article>
          {offerOptions
            .filter((o) => o.type === 10)
            .map((option) => (
              <Checkbox
                key={option.id}
                label={option.description}
                checked={checkboxes[option.id]}
                onChange={(e) => {
                  setChecboxes({
                    ...checkboxes,
                    [option.id]: e.target.checked,
                  });
                }}
              />
            ))}
        </article>

        <section
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            gap: "1rem",
          }}
        >
          <BlockButton onClick={onConfirm} type="submit" disabled={submitting}>
            {t("Done")}
          </BlockButton>
          <LinkButton
            as="a"
            href={createUrl(Page.CREATE_ORDER, { slug: "vedlegg" })}
          >
            {t("Tilbake")}
          </LinkButton>
        </section>
      </StyledSection>
    </PageFlexConstraint>
  );
};
