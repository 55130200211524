// eslint-disable-next-line no-shadow
export enum OfferOptionType {
  ROOF = 1,
  ROOFING = 2,
  JOIST_TYPE = 3,
  JOISTING = 4,
  PRECUT_DIMENSION = 5,
  SUBMERGE_WETROOM = 6,
}

export type OfferOption = {
  id: number;
  description: string;
  note: string;
  type: number;
};
