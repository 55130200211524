import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { CMSContent } from "../../components/CMSContent/CMSContent";
import { Heading } from "../../elements/Header/Header.styles";
import { useAppState } from "../../overmind";
import {
  ColumnWrapper,
  Text,
  BoldText,
} from "../order/ChooseProductPage/CreateOrderPage.styles";

export const TechnicalServicesPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const state = useAppState();

  return (
    <Page title={t("Technical services")}>
      <CMSContent contentId={CMSArticles.TECHNICAL_SERVICES} />

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {state.technicalServices.map((service) => (
          <ColumnWrapper key={service.id}>
            <img src={service.bildeURL} alt="bilde av tjeneste" /> : <div />
            <Heading type="secondary">{service.navn}</Heading>
            <Text>{service.beskrivelse}</Text>
            <BoldText>Fra kr: {service.pris},-</BoldText>
          </ColumnWrapper>
        ))}
      </div>
    </Page>
  );
};
