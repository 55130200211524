import * as yup from "yup";

export const string = yup.string();
export const boolean = yup.boolean();
export const requiredField = yup.string().required("Påkrevd");

export const dateInFuture = yup
  .date()
  .typeError("Vennligst velg en dato")
  .min(new Date(), "Dato kan ikke være i fortiden");

const nextWeek = new Date();

nextWeek.setDate(nextWeek.getDate() + 13);
export const dateTwoWeekInFuture = yup
  .date()
  .when("preferredDeliveryDate", (value) => {
    if (value && value instanceof Date && !Number.isNaN(value)) {
      return yup
        .date()
        .typeError("Vennligst velg en dato")
        .min(nextWeek, "Må være minst to uker frem i tid");
    }
    return yup.string().optional();
  });

export const dateTwoWeekInFutureRequired = yup
  .date()
  .typeError("Vennligst velg en dato")
  .min(nextWeek, "Må være minst to uker frem i tid")
  .required();
export const positiveNumeric = yup
  .number()
  .nullable()
  .transform((value, originalValue) =>
    String(originalValue).trim() === "" ? null : value
  )
  .typeError("Må være tall")
  .positive("Vennligst velg en gyldig verdi");

export const decimalField = yup
  .number()
  .nullable()
  .typeError("Må være tall")
  .positive("Vennligst velg en gyldig verdi")
  .transform((value: string, originalValue: string) => {
    if (String(originalValue).trim() === "") {
      return null;
    }
    return parseFloat(originalValue.replace(",", "."));
  })
  .min(0.0, "Må være mellom 0 - 60")
  .max(60.0, "Må være mellom 0 - 60")
  .typeError("Må være tall");

export const positiveRequiredNumeric = positiveNumeric.required("Påkrevd");

export const name = requiredField;
export const address = requiredField;
export const zipcode = requiredField;
export const city = requiredField;
export const email = yup.string().email();
export const requiredEmail = yup
  .string()
  .email()
  .required("Vennligst fyll inn epost");
export const deliveryDate = yup
  .date()
  .typeError("Vennligst velg en gyldig dato")
  .min(new Date(), "Vennligst velg en gyldig dato")
  .required("Vennligst velg en gyldig dato");
export const customerOrderNumber = yup
  .number()
  .typeError("Vennligst fyll inn gyldig nummer")
  .positive("Vennligst fyll inn et positivt nummer")
  .required("Vennligst fyll inn kundens ordrenummer");

export const heightAboveSeaLevel = yup
  .number()
  .typeError("Vennligst fyll inn gyldig nummer")
  .positive("Vennligst fyll inn et positivt nummer")
  .required("Vennligst fyll inn moh");

export const phoneNumber8digits = yup
  .string()
  .max(8, "Telefonnummer kan ikke være lengre enn 8 tegn")
  .matches(/^\d*$/, "Vennligst fyll inn gyldig telefonnummer");
