import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { Page } from "@frontend/common/src/elements/Page/Page";
import {
  BlockButton,
  LinkButton,
} from "@frontend/common/src/elements//Buttons/Buttons.styles";
import { useActions, useEffects } from "../../../overmind";

// Components
import { Form } from "../UnauthForm.styles";

import { ButtonGroup } from "../../../components/ButtonGroup/ButtonGroup.styles";

const loginSchema = yup.object().shape({
  email: yup.string().email().required("Vennligst fyll inn epost"),
});

export const ForgotPasswordPage: FunctionComponent = () => {
  // const [email, setEmail] = React.useState("");
  // const [password, setPassword] = React.useState("");

  const actions = useActions();
  const effects = useEffects();

  const { register, handleSubmit, errors } = useForm<{ email: string }>({
    resolver: yupResolver(loginSchema),
  });
  const onSubmit = (data: { email: string }) => {
    actions.auth.forgotPassword(data);
  };

  return (
    <Page title="Glemt Passord">
      <Form>
        <div style={{ maxWidth: "450px" }}>
          <FormInput
            htmlFor="email"
            name="email"
            label="Epost"
            type="text"
            defaultValue=""
            placeholder="Din epost.."
            inputRef={register}
            error={errors.email?.message}
          />
          <ButtonGroup>
            <BlockButton onClick={handleSubmit(onSubmit)}>
              Reset passord
            </BlockButton>
            <LinkButton onClick={() => effects.router.navigateTo("/")}>
              Avbryt
            </LinkButton>
          </ButtonGroup>
        </div>
      </Form>
    </Page>
  );
};
