import React, { FunctionComponent } from "react";
import { Page } from "@frontend/common/src/types/Page";
import { MainMenu } from "../components/MainMenu";

type Props = {
  children: any;
  currentPage: Page;
};

export const GuestLayout: FunctionComponent<Props> = ({
  children,
  currentPage,
}) => {
  return (
    <>
      <MainMenu currentPage={currentPage} user={null} />
      {children}
    </>
  );
};
