import styled from "styled-components";
import { PreTreColors } from "../../styles/constants";

export const Form = styled("form")`
  padding: 24px 24px 0 24px;
  height: auto;
`;
export const FormHeader = styled("h1")`
  font-size: 2.625rem;
  color: ${PreTreColors.beis};
  text-align: center;
`;
