import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileInput } from "@frontend/common/src/elements/FileInput/FileInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as validators from "@frontend/common/src/validators";
import { TextArea } from "@frontend/common/src/components/TextArea/TextArea";
import {
  BlockButton,
  LinkButton,
} from "@frontend/common/src/elements/Buttons/Buttons.styles";

import { Page } from "@frontend/common/src/types/Page";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import { Header } from "../../../elements/Header/Header";
import { useActions, useEffects } from "../../../overmind";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { createUrl } from "../../../app/pages";

import { FormInput } from "../../../components/FormInput/FormInput";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import { DraftOrder } from "../../../types/DraftOrder/DraftOrder";
import { ColumnWrapper, HeaderWrapper, Text } from "./CreateOrderPage.styles";

export const Attachments: FunctionComponent = () => {
  const { t } = useTranslation();
  const effects = useEffects();
  const actions = useActions();

  const [comment, setComment] = useState("");

  const [files, setFiles] = useState<File[]>([]);

  const { register, control, handleSubmit, errors, watch } =
    useForm<DraftOrder>({
      resolver: yupResolver(
        yup.object().shape({
          expectedDrawingDate: yup.mixed().when("hasDrawings", {
            is: true,
            then: validators.dateInFuture.required(),
          }),
        })
      ),
    });

  const hasDrawings = watch("hasDrawings") as boolean;
  const addFiles = (f: FileList) => {
    const file = files.concat(Array.from(f));
    setFiles(file);
  };
  const removeFile = (name: string) => {
    setFiles(files.filter((x) => x.name !== name));
  };
  const onSubmit = (data: DraftOrder) => {
    actions.orders.updateDraftOrder({ ...data, additionalInfo: comment });
    actions.orders.setDraftOfferFiles(files);
    effects.router.navigateTo(
      createUrl(Page.CREATE_ORDER, { slug: "oppsummering" })
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexDirection: "row" }}
    >
      <div>
        <HeaderWrapper>
          <Header type="secondary">5. {t("Attachments")}</Header>
        </HeaderWrapper>
        <ColumnWrapper>
          <Text>
            <CMSContent contentId={CMSArticles.ATTACHMENT_INFO_ORDERS} />
          </Text>
          <FileInput
            name="upload"
            label={t("Attach document")}
            multiple
            onChange={(f) => {
              addFiles(f);
            }}
          />
          <TextArea
            label={t("Add additional info here")}
            name="additionalInfo"
            rows={7}
            value={comment}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setComment(e.target.value)
            }
          />
          <div style={{ display: "flex" }}>
            <BlockButton style={{ width: "1000px" }} type="submit">
              Neste
            </BlockButton>
            <LinkButton
              as="a"
              href={createUrl(Page.CREATE_ORDER, { slug: "produktvalg" })}
            >
              {t("Tilbake")}
            </LinkButton>
          </div>
        </ColumnWrapper>
      </div>

      <ColumnWrapper>
        <Controller
          name="expectedDrawingDate"
          control={control}
          render={(props) => (
            <Checkbox
              name="hasDrawings"
              checked={props.value}
              onChange={(e) => props.onChange(e.target.checked)}
              label="Har ikke tegninger"
              inputRef={register}
            />
          )}
        />
        <CMSContent contentId={CMSArticles.ORDER_NO_DRAWINGS} />
        <FormInput
          name="expectedDrawingDate"
          type="date"
          label={t("Forventet levering på tegninger")}
          inputRef={register}
          error={errors.expectedDrawingDate?.message}
          required={hasDrawings}
          disabled={!hasDrawings}
        />
        <Text>Valgte filer for prosjekt: </Text>
        {files.map((x) => {
          return (
            <div style={{ marginBottom: "0.5em" }}>
              <button
                type="button"
                onClick={() => removeFile(x.name)}
                style={{ marginRight: "2em" }}
              >
                Fjern fil
              </button>
              {x.name}
            </div>
          );
        })}
      </ColumnWrapper>
    </form>
  );
};
