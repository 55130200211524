import {
  MIN_ORDER_STATUS_WHEN_COMPLETED,
  Order,
  OrderFilter,
} from "../../../types/Order";

const isset = (value: any) => value !== undefined && value !== null;

export function filterOrder(
  order: Order,
  filter: OrderFilter,
  currentUserId: string
) {
  if (isset(filter.status) && order.valueChainStatus !== filter.status) {
    return false;
  }

  if (isset(filter.projectId) && order.id !== filter.projectId) {
    return false;
  }

  if (isset(filter.freeText)) {
    const mainOrderContains = Object.values(order).some((x) => {
      return (
        x !== null &&
        x !== undefined &&
        x.toString().toLowerCase().indexOf(filter.freeText.toLowerCase()) > -1
      );
    });
    if (mainOrderContains) {
      return true;
    }
    return order.subProjects.some((or) => {
      return Object.values(or).some((x) => {
        return (
          x !== null &&
          x !== undefined &&
          x.toString().toLowerCase().indexOf(filter.freeText.toLowerCase()) > -1
        );
      });
    });
  }

  if (
    isset(filter.projectName) &&
    !`${order.projectName}`
      .toLowerCase()
      .includes(filter.projectName.toLowerCase())
  ) {
    return false;
  }

  if (
    isset(filter.customerOrderNumber) &&
    !order.customerOrderNumber
      ?.toString()
      .includes(filter.customerOrderNumber.toString())
  ) {
    return false;
  }

  if (
    isset(filter.showOnlyMyOrdersAndProjects) &&
    filter.showOnlyMyOrdersAndProjects === true &&
    order.customerEmployeeId !== currentUserId
  ) {
    return false;
  }
  if (
    isset(filter.product) &&
    !order.subProjects.some((x) => x.product === filter.product)
  ) {
    return false;
  }
  if (isset(filter.deliveryYear)) {
    const year = order.delivery.getFullYear();

    if (year !== filter.deliveryYear) {
      return false;
    }
  }

  if (
    isset(filter.contactId) &&
    order.customerEmployeeId !== filter.contactId
  ) {
    return false;
  }

  if (
    isset(filter.hideDeliveredOrders) &&
    filter.hideDeliveredOrders === true &&
    order.status >= MIN_ORDER_STATUS_WHEN_COMPLETED
  ) {
    return false;
  }
  if (
    isset(filter.showOnlyProjects) &&
    filter.showOnlyProjects === true &&
    order.isProjectMaster === false
  ) {
    return false;
  }

  return true;
}
