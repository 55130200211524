import React, { FunctionComponent } from "react";
import { ShortcutLink, ArrowIcon } from "./ShortcutCard.styles";

type ItemProps = {
  to?: string;
  ref?: any;
  as?: any;
} & React.HTMLProps<HTMLDivElement>;

export const ShortcutCardLink: FunctionComponent<ItemProps> = ({
  to,
  children,
  ...props
}) => {
  return (
    <ShortcutLink href={to} {...props}>
      {children}
    </ShortcutLink>
  );
};

export const ShortcutArrowIcon: FunctionComponent = () => {
  return (
    <ArrowIcon
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 8.50708H23.1328V11.4915H0V8.50708Z" fill="#CA0000" />
      <path
        d="M16.7161 20L14.6257 17.9096L22.5368 10L14.6257 2.09038L16.7161 0L26.7147 10L16.7161 20Z"
        fill="#CA0000"
      />
    </ArrowIcon>
  );
};
