import styled, { css } from "styled-components";

type Props = {
  suffix?: string;
};

const getSuffixStyle = (suffix: string) => {
  return css`
    position: relative;

    ::after {
      position: absolute;
      top: 50px;
      right: 1.5rem;
      transition: all 0.05s ease-in-out;
    }
    :hover::after,
    :focus-within::after {
      right: 1.5em;
    }

    ::after {
      content: "${suffix}";
    }
  `;
};

export const FormControl = styled("div")<Props>`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  ${(props: Props) => props.suffix && getSuffixStyle(props.suffix)}
`;

export const Error = styled("p")`
  color: red;
  margin-top: 0.5rem;
  &:first-letter {
    text-transform: uppercase;
  }
`;
