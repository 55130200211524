import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Page as PageConstants } from "@frontend/common/src/types/Page";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { useAppState } from "../../../overmind";
import { OrderFilter } from "./components/OrderFilter/OrderFilter";
import { OrderList } from "../../../components/OrderList/OrderList";
import { Pager } from "../../../components/Pager/Pager";
import { createUrl } from "../../../app/pages";

export const OrderListPage: FunctionComponent = () => {
  const state = useAppState();
  const { t } = useTranslation();
  return (
    <Page title={t("My orders and projects")}>
      <OrderFilter orders={state.orders.orders} />
      {state.orders.isFetching ? (
        <div>Laster</div>
      ) : (
        <>
          <OrderList
            noResultsText="Fant ingen ordre, sjekk filter over"
            orders={state.orders.currentPageItems}
            columns={[
              "id",
              "projectName",
              "status",
              "constructionName",
              "calculationName",
              "customerOrderNumber",
              "delivery",
              "sum",
            ]}
          />
          <Pager
            pageCount={state.orders.pageCount}
            currentPage={state.orders.currentPage}
            link={createUrl(PageConstants.ORDER_LIST)}
          />
        </>
      )}
    </Page>
  );
};
