import { useEffect } from "react";

export function useKeyDown(callback: () => void, keysToListenTo: number[]) {
  useEffect(() => {
    function onKeyDown(e: any) {
      if (keysToListenTo.indexOf(e.which) !== -1) {
        callback();
      }
    }
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  });
}
