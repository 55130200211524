import React, { FunctionComponent } from "react";
import { Page } from "@frontend/common/src/types/Page";
import { useAppState } from "../../../overmind";

import { MainMenu } from "../components/MainMenu";
import { SendMessageModal } from "../../../modals/SendMessageModal/SendMessageModal";

type Props = {
  children: any;
  currentPage: Page;
};

export const MainLayout: FunctionComponent<Props> = ({
  children,
  currentPage,
}) => {
  const state = useAppState();

  let displayName = "";
  if (state.auth.user !== null) {
    if (state.currentEmployee) {
      displayName = `${state.currentEmployee.name} (${state.customer.name})`;
    } else if (state.customer) {
      displayName = state.customer.name;
    }
  }

  //  customer={state.customer} customerEmployee={state.currentEmployee}
  return (
    <>
      <MainMenu
        currentPage={currentPage}
        user={state.auth.user}
        displayName={displayName}
      />
      {children}
      {state.modals.sendMessageModal.isCurrent ? <SendMessageModal /> : null}
    </>
  );
};
