import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { useAppState } from "../../overmind";

export const ErrorPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const state = useAppState();

  return <Page title={t("Error")}>{state.error && state.error.message}</Page>;
};
