import { CreateEmployeeMessage } from "@frontend/common/src/types/EmployeeMessage";
import { Employee } from "@frontend/common/src/types/Employee";
import { BaseService } from "../BaseService";
import { endpoints } from "../endpoints";
import { IEmployeeService } from "./IEmployeeService";

export class EmployeeService extends BaseService implements IEmployeeService {
  async sendMessage(
    createEmployeeMessage: CreateEmployeeMessage,
    customerId: number
  ): Promise<any> {
    return await this.makeRequest(endpoints.employee.sendMessage, {
      employeeId: createEmployeeMessage.employeeId,
      melding: createEmployeeMessage.message,
      referanseId: createEmployeeMessage.referenceId,
      referanseType: createEmployeeMessage.referenceType,
      customerId,
    }).then((r) => r.data);
  }

  async getAll(): Promise<Employee[]> {
    const results: any[] = await this.makeRequest(
      endpoints.employee.getAll
    ).then((r) => r.data);
    const fixed = results.map(EmployeeService.convert);
    return fixed;
  }

  static convert(employee: any): Employee {
    return {
      id: employee.ansattID,
      name: employee.ansattNavn,
      email: employee.ansattEpost,
      phone: employee.ansattMobil,
      isEngieer: employee.ingenior !== 0,
      isSalesEngineer: employee.ingenior_Salg !== 0,
      hide: employee.skjul === 1,
      isSales: employee.salgssjef !== 0,
      isAdmin: employee.crM_Admin !== 0,
      photoUrl: employee.doC_ID
        ? `https://www.pretre.no/prod_images/doc_${employee.doC_ID}_13.jpg`
        : null,
    };
  }
}
