import React from "react";
import { Calculation } from "../../../types/DraftOrder/Calculation";
import { Header } from "../../../elements/Header/Header";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { formatMoney } from "../../../helpers/money";

export const CalculationPriceAndError = ({ calc }: { calc: Calculation }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {calc && calc.error !== null && calc.error !== undefined ? (
        <div>
          <Header type="secondary">Feil</Header>
          <div>{calc.error} </div>
        </div>
      ) : calc === undefined ? (
        <div>
          <Header type="secondary">Informasjon</Header>
          <CMSContent contentId={1464} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "2em",
            }}
          >
            <Header type="secondary">Pris</Header>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                height: "100%",
              }}
            >
              <div>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <CMSContent contentId={1467} />
                  <span style={{ fontWeight: "bolder", marginLeft: "0.5rem" }}>
                    {formatMoney(calc.price)} eks mva.
                  </span>
                </span>
                {calc.freight > 0 ? (
                  <span>
                    Herav frakt
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "1.5rem",
                      }}
                    >
                      {formatMoney(calc.freight)} eks mva.{" "}
                    </span>
                  </span>
                ) : null}
                {calc.priceConditions !== null ? (
                  <div style={{ fontSize: "0.75rem" }}>
                    {calc.priceConditions.map((x) => (
                      <p>{x}</p>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
