import {
  createActionsHook,
  createStateHook,
  createEffectsHook,
} from "overmind-react";
import { IContext } from "overmind";

import { config } from "./config";

export type Context = IContext<{
  state: typeof config.state;
  actions: typeof config.actions;
  effects: typeof config.effects;
}>;

export type RootState = typeof config.state;

export const useAppState = createStateHook<Context>();
export const useActions = createActionsHook<Context>();
export const useEffects = createEffectsHook<Context>();
// export const useReaction = createReactionHook<Context>()
