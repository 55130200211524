import { BuildingType } from "../../../types/BuildingType";
import { OfferOption } from "../../../types/OfferOption";
import { TechnicalService } from "../../../types/TechnicalService";
import { BaseService } from "../BaseService";
import { endpoints } from "../endpoints";
import { ILookupsService } from "./ILookupsService";

type LookupResponse = {
  byggTyper: any[];
  tilbudsvalg: any[];
  tekniskeTjenester: any[];
  tilbudsStatuser: any[];
  ordreStatuser: any[];
};
export class LookupsService extends BaseService implements ILookupsService {
  async getLookups(): Promise<{
    buildingTypes: BuildingType[];
    offerOptions: OfferOption[];
    technicalServices: TechnicalService[];
    orderStatuses: any[];
    offerStatuses: any[];
  }> {
    const results: LookupResponse = await this.makeRequest(
      endpoints.lookups.getLookups
    ).then((r) => r.data);

    return {
      buildingTypes: results.byggTyper.map(LookupsService.convertBuildingType),
      offerOptions: results.tilbudsvalg.map(LookupsService.convertOfferOption),
      technicalServices: results.tekniskeTjenester,
      orderStatuses: results.ordreStatuser,
      offerStatuses: results.tilbudsStatuser,
    };
  }

  async getCountyForZipcode(zipcode: string): Promise<string> {
    return await this.makeRequest(endpoints.lookups.zipcodeLookup, {
      zipcode,
    }).then((r) => r.data.poststed);
  }

  async getConfigValue(configKey: string): Promise<string> {
    return await this.makeRequest(endpoints.lookups.getConfigValue, {
      configKey,
    }).then((r) => r.data);
  }

  static convertOfferOption(o: any): OfferOption {
    return {
      id: o.id,
      description: o.beskrivelse,
      note: o.notat,
      type: o.type,
    };
  }

  static convertBuildingType(b: any): BuildingType {
    return {
      id: b.id,
      typeNr: b.byggTypeNr,
      name: b.byggtype,
    };
  }
}
