/* eslint-disable no-shadow */
import React, { useState } from "react";
import { ColumnPicker } from "./components/ColumPicker/ColumnPicker";
import { Direction } from "./Direction";
import { SortIcon } from "./elements/SortIcon/SortIcon";

import {
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableHeading,
  TableHeadingText,
} from "./Table.styles";

export type Column<T> = {
  headerName: string;
  field?: keyof T;
  alignEnd?: boolean;
  render?: (row: T, openRows?: string[]) => any;
};

type Props<T> = {
  columns: Column<T>[];
  availableColumns: Column<T>[];
  data: T[];
  keyField: any;
  onRowSelected?: (row: T) => void;
  noResultsText?: string;
  onSortChanged?: (field: keyof T, direction: Direction) => void;
  currentSortOption?: { field: keyof T; direction: Direction };
  openRows?: string[];
};

export const Table = <T extends { [index: string]: any }>({
  columns,
  data,
  keyField,
  onRowSelected,
  availableColumns,
  noResultsText,
  onSortChanged,
  currentSortOption,
  openRows,
}: Props<T>) => {
  const [pickColumnsOpen, setPickColumnsOpen] = useState(false);
  const [currentColumns, setCurrentColumns] = useState(columns);
  return (
    <div style={{ position: "relative" }}>
      {/* <button onClick={() => setPickColumnsOpen(!pickColumnsOpen)}>Velg kolonner</button> */}
      <TableContainer>
        <TableHead>
          <TableRow>
            {columns.map((c, idx) => {
              const style: any = { display: "flex", alignItems: "center" };
              if (c.alignEnd) {
                style.justifyContent = "flex-end";
              }
              if (c.field) {
                return (
                  <TableHeading
                    onClick={() => {
                      if (onSortChanged) {
                        onSortChanged(
                          c.field,
                          currentSortOption
                            ? currentSortOption.direction ===
                              Direction.ascending
                              ? Direction.descending
                              : Direction.ascending
                            : Direction.descending
                        );
                      }
                    }}
                    key={idx}
                  >
                    <div style={style}>
                      <TableHeadingText>{c.headerName}</TableHeadingText>
                      {onSortChanged ? (
                        <SortIcon
                          direction={
                            currentSortOption &&
                            c.field === currentSortOption.field
                              ? currentSortOption.direction
                              : Direction.initial
                          }
                        />
                      ) : null}
                    </div>
                  </TableHeading>
                );
              }

              return (
                <TableHeading key={idx}>
                  <TableHeadingText>{c.headerName}</TableHeadingText>
                </TableHeading>
              );
            })}
          </TableRow>
        </TableHead>
        <tbody>
          {!data.length && (
            <tr>
              <td
                style={{ textAlign: "center", padding: "2rem" }}
                colSpan={currentColumns.length}
              >
                {noResultsText}
              </td>
            </tr>
          )}
          {data.map((row, rowIdx) => (
            <>
              <TableRow
                key={row[keyField]}
                hasOpenHandler={!!onRowSelected}
                alternate={rowIdx % 2 === 0}
                onClick={() => {
                  if (onRowSelected) {
                    onRowSelected(row);
                  }
                }}
              >
                {columns.map((c, idx) => {
                  if (c.render) {
                    return (
                      <TableCell key={idx}>{c.render(row, openRows)}</TableCell>
                    );
                  }
                  return <TableCell key={idx}>{row[c.field]}</TableCell>;
                })}
              </TableRow>
              {row.subProjects !== undefined &&
                row.subProjects.map((subRow: any, i: number) => {
                  return (
                    <TableRow
                      child
                      notVisible={!openRows.includes(`${row[keyField]}`)}
                      key={subRow[keyField]}
                      alternate={(i + 1) % 2 === 0}
                    >
                      {columns.map((col, index) => {
                        if (col.render) {
                          return (
                            <TableCell child={index === 0} key={index}>
                              {col.render(subRow)}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={index}>{subRow[col.field]}</TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </>
          ))}
        </tbody>
      </TableContainer>
      {pickColumnsOpen ? (
        <ColumnPicker
          onClose={() => setPickColumnsOpen(false)}
          onColumnsChanged={(currentColumns: string[]) => {
            setCurrentColumns(
              availableColumns.filter((a) =>
                currentColumns.includes(a.headerName)
              )
            );
          }}
          availableColumns={availableColumns.map((c) => c.headerName)}
          selectedColumns={currentColumns.map((c) => c.headerName)}
        />
      ) : null}
    </div>
  );
};
