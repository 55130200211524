import React, { FunctionComponent } from "react";
import { Input } from "./FormInput.styles";
import { Label } from "../Label/Label";
import { FormControl, Error } from "../FormControl/FormControl";
import { RequiredFieldIndicator } from "../RequiredFieldIndicator/RequiredFieldIndicator";

type Props = {
  htmlFor?: string;
  label?: string;
  name: string;
  type: string;
  placeholder?: string;
  defaultValue?: string;
  inputRef?: any;
  error?: any;
  required?: boolean;
  suffix?: string;
  onChange?: (value: string) => void;
  readonly?: any;
  disabled?: any;
};
export const FormInput: FunctionComponent<Props> = ({
  htmlFor,
  label,
  name,
  type,
  placeholder,
  defaultValue,
  inputRef,
  error,
  required,
  suffix,
  onChange,
  disabled,
  readonly,
}) => {
  return (
    <FormControl suffix={suffix}>
      <Label htmlFor={htmlFor}>
        {label || null}
        {required ? <RequiredFieldIndicator /> : ""}
      </Label>
      <Input
        readOnly={readonly}
        name={name}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        ref={inputRef}
        error={!!error}
        disabled={disabled}
        onChange={(e) => (onChange ? onChange(e.target.value) : () => {})}
      />

      {error ? <Error>{error}</Error> : null}
    </FormControl>
  );
};
