export const PreTreColors = {
  pretre: "#CA0000",
  redbrown: "#441B17",
  grillaBrite: "#C24E44",
  beis: "#430A03",
  piemonte: "#891F0C",
  weakBrite: "#FBF4F4",
  borderlineGray: "#D9D9D9",
};

export const LayoutConstants = {
  sidebarBreak: 900,
};
