import React, { FunctionComponent, useMemo } from "react";
import { BlockButton } from "../../../components/Buttons/Buttons.styles";
import { PreTreColors } from "../../../styles/constants";
import { DraftOrderSubProject } from "../../../types/DraftOrder/DraftOrderSubProject";
import { OfferOptionType } from "../../../types/OfferOption";
import { useAppState } from "../../../overmind";

interface props {
  subProjects: DraftOrderSubProject[];
  setSubProjects: (projects: DraftOrderSubProject[]) => void;
  selectedSubProject: DraftOrderSubProject;
  setSelectedSubProject: (project: DraftOrderSubProject) => void;
  setActiveForm: (form: string) => void;
  autoFill: boolean;
}

export const SubProjects: FunctionComponent<props> = ({
  subProjects,
  setSubProjects,
  selectedSubProject,
  setSelectedSubProject,
  setActiveForm,
  autoFill,
}) => {
  const state = useAppState();
  const roofTypes = state.offerOptions
    .filter((o) => o.type === OfferOptionType.ROOF)
    .map((o) => ({ label: o.description, value: o.id }));

  const joistsTypes = useMemo(
    () =>
      state.offerOptions
        .filter((o) => o.type === OfferOptionType.JOIST_TYPE)
        .map((o) => ({ label: o.description, value: o.id })),
    [state.offerOptions]
  );

  const dimensions = useMemo(
    () =>
      state.offerOptions
        .filter((o) => o.type === OfferOptionType.PRECUT_DIMENSION)
        .map((o) => ({ label: o.description, value: o.id })),
    [state.offerOptions]
  );

  const deterimineProductName = (subProj: DraftOrderSubProject) => {
    let returnVal = "";
    if (subProj.type === "technical") {
      returnVal = "Tekniske tjenester";
    } else if (subProj.type === "roof" || subProj.type === "extendedRoof") {
      const roof = roofTypes.find(
        (x) => x.value.toString() === subProj.roofType
      );
      returnVal = roof ? roof.label : "";
    } else if (
      subProj.type === "beamlayer" ||
      subProj.type === "extendedBeamlayer"
    ) {
      const joist = joistsTypes.find(
        (x) => x.value.toString() === subProj.joistsType
      );
      returnVal = joist ? joist.label : "";
    } else if (subProj.type === "precut" || subProj.type === "extendedPrecut") {
      const dim = dimensions.find(
        (x) => x.value.toString() === subProj.dimensions
      );
      returnVal = `Precut ${dim ? dim.label : ""}`;
    } else {
      returnVal = subProj.type;
    }
    return returnVal !== "" ? returnVal : subProj.type;
  };
  const deleteProject = (subProject: DraftOrderSubProject) => {
    if (autoFill) {
      const projects = subProjects.filter(
        (x) => x.projectId !== subProject.projectId
      );
      setSubProjects([...projects, { ...subProject, deleted: true }]);
    } else {
      const projects = subProjects.filter(
        (x) => x.projectId !== subProject.projectId
      );
      setSubProjects(projects);
    }
  };

  const unDeleteProject = (subProject: DraftOrderSubProject) => {
    const map = subProjects.map((x) => {
      if (x.projectId === subProject.projectId) {
        return {
          ...x,
          deleted: false,
        };
      }
      return x;
    });
    setSubProjects(map);
  };
  const onClickProject = (subProject: DraftOrderSubProject) => {
    if (subProject.deleted !== true) {
      setSelectedSubProject(subProject);
      setActiveForm(subProject.type);
    }
  };
  return (
    <div
      style={{
        minWidth: "20vw",
        backgroundColor: PreTreColors.borderlineGray,
        borderRadius: "10px",
        height: "50vh",
      }}
    >
      <div
        style={{
          backgroundColor: PreTreColors.pretre,
          color: "white",
          fontSize: "1.2rem",
          padding: "15px 0",
          borderRadius: "10px",
          textAlign: "center",
          height: "3.5em",
        }}
      >
        Valgte produkter i prosjektet:
      </div>
      <div style={{ overflowY: "auto", maxHeight: "45vh" }}>
        {subProjects.map((proj, index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "1em",
              }}
            >
              <div
                style={{
                  backgroundColor:
                    selectedSubProject === proj ? "gray" : "white",
                  display: "flex",
                  justifyContent: "space-between",
                  color: proj.deleted !== true ? "black" : "gray",
                  padding: "1em",
                  borderRadius: "10px",
                }}
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                  onClick={() => onClickProject(proj)}
                >
                  <span
                    style={{ fontSize: "1.2rem", textDecoration: "underline" }}
                  >
                    {index + 1}. {deterimineProductName(proj)}
                  </span>
                  <span>
                    {proj.comment?.length > 0 ? `(${proj.comment})` : ""}
                  </span>
                </div>
                {proj.deleted === true ? (
                  <BlockButton
                    type="button"
                    style={{ width: "30%", height: "2.5em", fontSize: "1rem" }}
                    onClick={() => unDeleteProject(proj)}
                  >
                    Legg til
                  </BlockButton>
                ) : (
                  <BlockButton
                    type="button"
                    style={{ width: "20%", fontSize: "1rem" }}
                    onClick={() => deleteProject(proj)}
                  >
                    Fjern
                  </BlockButton>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
