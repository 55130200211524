import styled from "styled-components";

import { baseInputStyles } from "webapp/src/styles/baseInput";

export const InputArea = styled("textarea")`
  ${baseInputStyles}
  width: 100%;
  &[disabled] {
    background: #ddd;
    border: 1px solid #b7b7b7;
  }
`;
