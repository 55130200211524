import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@frontend/common/src/components/Modal/Modal";
import { useActions, useAppState } from "../../overmind";
import {
  BlockButton,
  LinkButton,
} from "../../components/Buttons/Buttons.styles";
import { ExtendedPrecut } from "../../types/DraftOrder/ExtendedPrecut";
import PrecutModalForm from "./PrecutModalForm";
import { PrecutPlans } from "./PrecutPlans";

export const PrecutModal: FunctionComponent = () => {
  const { modals } = useAppState();
  const { t } = useTranslation();
  const [plans, setPlans] = useState<ExtendedPrecut[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<ExtendedPrecut | undefined>(
    undefined
  );
  const [formActive, setFormActive] = useState<boolean>(true);
  const { modals: modalsActions } = useActions();

  if (!modals.precutModal.isCurrent) {
    return null;
  }

  const onSubmit = () => {
    modalsActions.precutModal.close(plans);
  };
  return (
    <Modal
      isOpen={modals.precutModal.isCurrent}
      onClose={() => modalsActions.precutModal.close(null)}
    >
      <div
        style={{
          width: "80vw",
          display: "flex",
          flexDirection: "column",
          minHeight: "90vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            minHeight: "85vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <PrecutPlans
              setPlans={setPlans}
              plans={plans}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              setFormActive={setFormActive}
              autoFill={false}
            />
            <BlockButton
              onClick={() => setFormActive(true)}
              disabled={formActive}
            >
              Legg til plan
            </BlockButton>
          </div>
          <div style={{ display: "flex", flex: "1", marginLeft: "4em" }}>
            {formActive && (
              <PrecutModalForm
                plans={plans}
                setPlans={setPlans}
                selectedPlan={selectedPlan}
                setFormActive={setFormActive}
                setSelectedPlan={setSelectedPlan}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            justifySelf: "flex-end",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", width: "20%" }}>
            <BlockButton type="button" onClick={() => onSubmit()}>
              {t("Done")}
            </BlockButton>
            <LinkButton
              as="a"
              onClick={() => modalsActions.precutModal.close(null)}
            >
              {t("Avbryt")}
            </LinkButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
